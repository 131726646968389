import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, MultiSelect, Text } from "bluejay-ui";
import { Navigation } from "ui";
import styled from "styled-components";

const getInitialState = (requiredInputs) =>
  requiredInputs.map((input) => {
    switch (input.name) {
      case "isBagsMandatory":
        return {
          ...input,
          value: input.value === "" ? "0" : input.value,
        };
      case "isPassengersMandatory":
        return {
          ...input,
          value: input.value === "" ? "1" : input.value,
        };
      default:
        return input;
    }
  });

const ZoneRequiredPreferencesModal = ({
  requiredInputs = [],
  onConfirmClick = () => {},
}) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState(getInitialState(requiredInputs));

  const onInputChange = ({ name, value }) =>
    setInputs(
      inputs.map((preference) =>
        preference.name === name ? { ...preference, value } : preference
      )
    );

  return (
    <Modal title={t("zonePreferences.completeInformation")} data-testid="zone-required-prefs-modal">
      <InputsContainer>
        {inputs.map(({ name, value, options }) => (
          <React.Fragment key={name}>
            <Text as="p" color="default" style={{ marginBottom: 10 }}>
              {t(`zonePreferences.${name}`)}
            </Text>
            <MultiSelect
              key={name}
              isCreatable={false}
              isSearchable={false}
              isMulti={false}
              name={name}
              id={`${name}-required-select`}
              value={{ label: `${value}`, value: `${value}` }}
              options={options}
              onChange={({ value }) => onInputChange({ name, value })}
            />
          </React.Fragment>
        ))}
      </InputsContainer>
      <Navigation
        singleButton={true}
        leftButton={{
          show: false,
        }}
        rightButton={{
          children: t("actions.save"),
          color: "primary",
          disabled: !inputs.every(({ value }) => value && value !== ""),
          onClick: () => onConfirmClick(inputs),
          "data-testid": "required-bags-passengers-save-btn",
        }}
      />
    </Modal>
  );
};

const InputsContainer = styled.div`
  margin-bottom: 10px;
`;

export default ZoneRequiredPreferencesModal;
