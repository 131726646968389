import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { UnAuthPage } from "ui/";
import Login from "pages/Login";
import Register from "pages/Register";
import { RecoverPassword } from "pages";

const UnAuthApp = ({ login }) => {
  return (
    <Router>
      <UnAuthPage>
        <Switch>
          <Route path="/" exact>
            <Login login={login}></Login>
          </Route>
          <Route path="/register">
            <Register login={login}></Register>
          </Route>
          <Route path="/recover-password">
            <RecoverPassword></RecoverPassword>
          </Route>

          <Redirect to="/"></Redirect>
        </Switch>
      </UnAuthPage>
    </Router>
  );
};

export default UnAuthApp;
