import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const LoaderContainer = styled.div`
  position: relative;
  padding: 20px;
  ${({ loading }) => (loading === "true" ? "pointer-events: none;" : "")}
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 100;
`;

export default ({ children, loading, containerStyle, style }) => (
  <LoaderContainer style={containerStyle} loading={loading === true ? "true": "false"}>
    {loading && (
      <React.Fragment>
        <Loader style={style} loading={loading === true ? "true": "false"} />
        <Spinner />
      </React.Fragment>
    )}
    {children}
  </LoaderContainer>
);
