import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Modal } from "bluejay-ui";
import { Label, Link, Navigation } from "ui";
import { appTypes, getAppType } from "utils/app";

const Profile = ({ logout }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const onConfirmClick = () => {
    logout();
  };

  return (
    <>
      <Container>
        {getAppType() !== appTypes.HOTEL && (
          <LightLink
            as={RouterLink}
            to="/billing-data"
            style={{ padding: "10px 16px 20px 16px" }}
          >
            {t("profile.billingData")}
          </LightLink>
        )}
        <LightLink
          as={RouterLink}
          to="/change-password"
          style={{ paddingLeft: "16px" }}
        >
          {t("profile.changePassword")}
        </LightLink>
        <LightLink href="https://pidetaxi.es/privacy/" target="_blank">
          {t("profile.privacy")}
        </LightLink>
        <LightLink href="https://pidetaxi.es/legal/" target="_blank">
          {t("profile.tos")}
        </LightLink>
      </Container>
      <Navigation
        mode="vertical"
        leftButton={{
          children: t("actions.back"),
          onClick: () => {
            history.push("/");
          },
        }}
        rightButton={{
          children: t("actions.closeSession"),
          color: "warning",
          onClick: () => setShowModal(true),
        }}
      />
      {showModal && (
        <Modal Title={Label} title={t("profile.wishToCloseSession")}>
          <Navigation
            leftButton={{
              children: t("general.no"),
              onClick: () => setShowModal(false),
            }}
            rightButton={{
              children: t("general.yes"),
              color: "warning",
              onClick: onConfirmClick,
            }}
          />
        </Modal>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const LightLink = styled(Link)`
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.default};
  text-decoration: none;
`;

export default Profile;
