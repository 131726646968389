import React from "react";
import styled from "styled-components";
import background from "images/login-background.png";
import { responsive } from "bluejay-ui";

const { mediaQuery } = responsive;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  padding-top: 100px;
  padding-bottom: 100px;

  ${mediaQuery.TABLET`
    flex-direction: row;  
    padding-top: 0px;
    padding-bottom: 0px;  
  `}
`;

const Box = styled.div`
  padding: 50px 10%;

  ${mediaQuery.DESKTOP`
    padding-right: 15%;
  `}
`;

const ImageContainer = styled.div`
  display: none;
  width: 100%;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
  background-position: center;

  ${mediaQuery.RETINAL`
    display: block;
    padding-right: 15%;
  `}
`;

const UnAuthPage = ({ children }) => {
  return (
    <Container>
      <ImageContainer />
      <Box>{children}</Box>
    </Container>
  );
};

export default UnAuthPage;
