import styled from "styled-components";
import { Link } from "bluejay-ui";

const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;
  text-transform: none;
`;

export default CustomLink;
