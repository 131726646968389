import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AuthPage } from "ui";
import {
  BookingScreens,
  Profile,
  MyTrips,
  Trip,
  ChangePassword,
  BillingData,
  BillingDataUpsert,
} from "pages";

const AuthApp = ({ user, logout }) => {
  return (
    <Router>
      <AuthPage user={user}>
        {(props) => (
          <Switch>
            <Route path="/trips" exact>
              <MyTrips {...props} />
            </Route>
            <Route path="/trips/:id">
              <Trip {...props} />
            </Route>
            <Route path="/profile">
              <Profile {...props} logout={logout} />
            </Route>
            <Route path="/change-password">
              <ChangePassword {...props} />
            </Route>
            <Route path="/billing-data" exact>
              <BillingData {...props} />
            </Route>
            <Route path="/billing-data/new" exact>
              <BillingDataUpsert {...props} />
            </Route>
            <Route path="/billing-data/:id">
              <BillingDataUpsert {...props} />
            </Route>
            <BookingScreens {...props} />
            <Redirect to="/" />
          </Switch>
        )}
      </AuthPage>
    </Router>
  );
};

export default AuthApp;
