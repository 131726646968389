import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { Navigation } from "ui";
import { Text } from "bluejay-ui";
import { StyledInput, StyledTimer, InputsContainer } from "./styles.js";

const formatDateToYYYYMMDD = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const parseDateFromYYYYMMDD = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number); // convert each fragment to a number
  return new Date(year, month - 1, day);
};

const isValidDate = (dateString) => {
  // Check format
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const date = new Date(dateString);
  // Check if is a valid date
  if (Number.isNaN(date.getTime())) {
    return false;
  }
  // Check if the date matches the input string (to avoid dates like 2021-02-29)
  return date.toISOString().split("T")[0] === dateString;
};

const initTime = (now) => {
  const [hour, minute] = now.split(":");

  return {
    formatted24: now,
    hour: hour,
    minute: minute,
  };
};

const navigateToHome = (updateBooking, history) => {
  history.push("/");
  updateBooking({ bookingType: 0 });
};

const navigateToSummary = (history) => history.push("/summary");

const TripScheduleDatePicker = ({ updateBooking, trip }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const now = DateTime.local().toFormat("HH:mm");

  const [time, setTime] = useState(initTime(now));
  const [date, setDate] = useState(new Date());
  const { hour, minute } = time;

  const bookingDate = DateTime.fromJSDate(date)
    .set({ hour, minute, second: 0 })
    .toMillis();

  const bookingDateIsFuture = DateTime.local() <= bookingDate;

  //Due to input date limitations, input a year by kbd is only available trough the arrrow keys.
  const [arrowKeyPressed, setArrowKeyPressed] = useState(false);
  const prevDateRef = React.useRef(date);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      setArrowKeyPressed(true);
    }
  };

  const handleDateInput = (e) => {
    const userInput = e.currentTarget.value;
    const newDate = parseDateFromYYYYMMDD(userInput);
    const prevDate = prevDateRef.current;

    if (
      userInput.startsWith("000") &&
      !arrowKeyPressed &&
      newDate.getFullYear() !== prevDate.getFullYear()
    ) {
      e.target.value = formatDateToYYYYMMDD(prevDate);
      return;
    }

    if (isValidDate(userInput)) {
      setDate(newDate);
      prevDateRef.current = newDate;
    }

    setArrowKeyPressed(false);
  };
  // end kbd limitation logic

  useEffect(() => {
    updateBooking({ bookingDate });
  }, [time, date]);

  return (
    <>
      <InputsContainer>
        <div>
          <label htmlFor="user-date">Fecha de Reserva</label>
          <StyledInput
            type="date"
            id="user-date"
            onKeyDown={handleKeyDown}
            onInput={handleDateInput}
            value={formatDateToYYYYMMDD(date)}
            min={new Date().toISOString().split("T")[0]}
            onChange={(e) => {
              const userInput = e.target.value;
              if (isValidDate(userInput)) {
                setDate(parseDateFromYYYYMMDD(userInput));
              }
            }}
            data-testid="schedule-datePicker"
          />
        </div>
        <div>
          <label htmlFor="user-time">Hora de Reserva</label>
          <StyledTimer
            type="time"
            id="user-time"
            value={time.formatted24}
            onChange={(e) => {
              const newTime = e.target.value.split(":");
              setTime({
                formatted24: newTime.join(":"),
                hour: newTime[0],
                minute: newTime[1],
              });
            }}
            data-testid="schedule-timePicker"
          />
        </div>
      </InputsContainer>
      {!bookingDateIsFuture && (
        <Text color="danger" style={{ textAlign: "center" }}>
          {t("book.selectAFutureDate")}
        </Text>
      )}
      <Navigation
        leftButton={{
          children: t("actions.back"),
          onClick: () => navigateToHome(updateBooking, history),
        }}
        rightButton={{
          disabled: !trip.isValid || !bookingDateIsFuture,
          children: t("book.inFuture"),
          onClick: () => navigateToSummary(history),
        }}
      />
    </>
  );
};
export default TripScheduleDatePicker;
