import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navigation, Loader, Label } from "ui";
import { ReactComponent as DeleteIcon } from "images/close_orange.svg";
import { taxProfilesRequest, deleteTaxProfileRequest } from "utils/requests";
import { Modal } from "bluejay-ui";

const TaxProfiles = ({ isDeletable = false, onRowClick = () => {} }) => {
  const { t } = useTranslation();
  const [billingData, setBillingData] = useState([]);
  const [taxProfileToDelete, setTaxProfileToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTaxProfiles();
  }, []);

  const getTaxProfiles = () => {
    setLoading(true);
    taxProfilesRequest().then(({ data }) => {
      setBillingData(data);
      setLoading(false);
    });
  };

  const onDeleteConfirmClick = () => {
    deleteTaxProfileRequest(taxProfileToDelete).then(() => {
      getTaxProfiles();
      setTaxProfileToDelete(null);
    });
  };

  return (
    <>
      <Loader loading={loading}>
        {billingData && billingData.length > 0 ? (
          billingData.map(({ taxId, businessName }, index) => (
            <BillingElement
              key={index}
              data-testid={`taxProfile-${index}`}
              to={`/billing-data/${taxId}`}
              onClick={(e) => onRowClick({ e, taxId })}
            >
              <InfoContainer>
                <Nif>{taxId}</Nif>
                <BusinessName>{businessName}</BusinessName>
              </InfoContainer>
              {isDeletable && (
                <div>
                  <DeleteIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setTaxProfileToDelete(taxId);
                    }}
                  />
                </div>
              )}
            </BillingElement>
          ))
        ) : (
          <>{!loading && t("billingData.noBillingData")}</>
        )}
      </Loader>
      {taxProfileToDelete && (
        <Modal Title={Label} title={t("billingData.deleteModalTitle")}>
          <Navigation
            leftButton={{
              children: t("general.no"),
              onClick: () => setTaxProfileToDelete(null),
            }}
            rightButton={{
              children: t("general.yes"),
              color: "warning",
              onClick: onDeleteConfirmClick,
            }}
          />
        </Modal>
      )}
    </>
  );
};

const BillingElement = styled(Link)`
  display: flex;
  padding: 10px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  text-decoration: unset;
  color: #000;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Nif = styled.span``;

const BusinessName = styled.span``;

export default TaxProfiles;
