import React, { useState } from "react";
import styled from "styled-components";
import { responsive, Text } from "bluejay-ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Navigation, Input, Button } from "ui";
import { changeUserPassword } from "utils/requests";
import handleErrors from "utils/handleErrors";

const { mediaQuery } = responsive;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const Form = styled.form`
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.TABLET`    
    width: 350px;
  `}
`;

const modes = {
  PENDING: {
    name: "PENDING",
    message: {
      translation: "",
    },
  },
  SUCCESS: {
    name: "SUCCESS",
    message: {
      color: "primary",
      translation: "changePassword.success",
    },
  },
  WEAK: {
    name: "WEAK",
    message: {
      color: "danger",
      translation: "register.errors.weakPassword",
    },
    errorCode: 4108,
  },
  NOT_EQUAL: {
    name: "NOT_EQUAL",
    message: {
      color: "danger",
      translation: "changePassword.errors.NOT_EQUAL",
    },
    errorCode: 4107,
  },
  FAILURE: {
    name: "FAILURE",
    message: {
      color: "danger",
      translation: "changePassword.errors.FAILURE",
    },
    errorCode: 4004,
  },
  USER_CREDENTIALS_WRONG: {
    name: "USER_CREDENTIALS_WRONG",
    message: {
      color: "danger",
      translation: "changePassword.errors.USER_CREDENTIALS_WRONG",
    },
    errorCode: 4103,
  },
};

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  mode: modes.PENDING,
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  
  const [state, setState] = useState(initialState);
  
  const updateState = (nextState) =>
  setState((currentState) => ({ ...currentState, ...nextState }));
  
  const onInputChange = ({ target: { name, value } }) =>
  updateState({
    [name]: value,
    ...(state.mode !== modes.PENDING
      ? { mode: modes.PENDING }
        : {}),
    });

  const onSubmit = (e) => {
    e.preventDefault();

    changeUserPassword(state)
      .then(() => {
        setState({ ...initialState, mode: modes.SUCCESS });
      })
      .catch(customHandleErrors);
  };

  const customHandleErrors = ({ error }) => {
    const nextMode = handleErrors({ error, modes });
    if (nextMode) {
      updateState({ mode: nextMode });
    }
  };

  return (
    <>
      <Container>
        <Form onSubmit={onSubmit}>
          <Input
            label={t("changePassword.currentPassword")}
            name="currentPassword"
            id="currentPassword"
            onChange={onInputChange}
            value={state.currentPassword}
            type="password"
          />
          <Input
            label={t("changePassword.newPassword")}
            name="newPassword"
            id="newPassword"
            onChange={onInputChange}
            value={state.newPassword}
            type="password"
          />
          <Input
            label={t("changePassword.confirmPassword")}
            name="confirmPassword"
            id="confirmPassword"
            onChange={onInputChange}
            value={state.confirmPassword}
            type="password"
          />
          <Button
            color="primary"
            style={{ fontWeight: "bold", textTransform: "none" }}
            type="submit"
          >
            {t("actions.save")}
          </Button>
          <Text
            color={state.mode.message.color}
            style={{ margin: "30px auto", textAlign: "center" }}
          >
            {t(state.mode.message.translation)}
          </Text>
        </Form>
      </Container>
      <Navigation
        mode="vertical"
        leftButton={{
          show: false,
        }}
        rightButton={{
          children: t("actions.back"),
          color: "warning",
          onClick: () => {
            history.push("/profile");
          },
        }}
      />
    </>
  );
};

export default ChangePassword;
