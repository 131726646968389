import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { responsive } from "bluejay-ui";
import {
  CustomGoogleMap,
  FavouriteAddressModal,
  AddressFinder,
} from "components";
import { DirectionsTabs } from "ui";
import { mapGooglePlaceToTakasiAddress } from "utils/booking";
import { searchModes, geocodePlace, initialLocation } from "utils/map";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import { appTypes, getAppType } from "utils/app";

const { mediaQuery, useMedia, breakpoints } = responsive;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: white;
  opacity: 0.9;

  ${mediaQuery.TABLET`
    width: 30%;
  `}
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const DirectionsTabsContainer = styled.div`
  position: absolute;
  bottom: 90px;
  width: 100%;
`;

const Map = ({
  googleServices,
  mapConfig = {},
  updateMapConfig,
  routes,
  updateRoutes,
  onRouteChange,
  onMapLoad = () => {},
}) => {
  const appType = getAppType();
  const COORDINATES_PRECISION_DECIMAL_POINT = 5;
  const mainContainerRef = useRef();
  const [userPosition, setUserPosition] = useState(undefined);
  const isMobile = useMedia([breakpoints.TABLET], [false], true);
  const [searching, setSearching] = useState({
    isSearching: false,
    mode: searchModes.SUGGEST,
  });

  const [showModal, setShowModal] = useState(false);

  const updateSearching = (nextSearching) =>
    setSearching((searching) => ({ ...searching, ...nextSearching }));

  useOnClickOutside(mainContainerRef, () => {
    if (searching.isSearching) {
      updateSearching({ isSearching: false });
    }
  });

  useEffect(() => {
    updateMapConfig({ height: searching.isSearching ? "100vh" : "70vh" });
  }, [searching.isSearching]);

  useEffect(() => {
    if (
      routes.origin &&
      routes.origin.location &&
      routes.destination &&
      routes.destination.location
    ) {
      const center = googleServices?.getMapCenterFromLocations([
        routes.origin.location,
        routes.destination.location,
      ]);

      updateMapConfig({ center });
    } else if (
      appType !== appTypes.HOTEL &&
      routes.origin.location &&
      shouldCenterOfMapBeUpdated({
        lat: routes.origin.location.latitude,
        lng: routes.origin.location.longitude,
      })
    ) {
      updateMapConfig({
        center: {
          lat: routes.origin.location.latitude,
          lng: routes.origin.location.longitude,
        },
      });
    }
  }, [routes]);

  useEffect(() => {
    if (
      appType === appTypes.HOTEL &&
      routes.origin.location === undefined &&
      mapConfig.center.lat &&
      mapConfig.center.lng
    )
      geocodePlace(mapConfig.center, googleServices).then((geocodedPlace) => {
        updateRoutes({
          origin: mapGooglePlaceToTakasiAddress(geocodedPlace),
        });
      });
  }, [googleServices, mapConfig.center.lat, mapConfig.center.lng]);

  useEffect(() => {
    if (appType !== appTypes.HOTEL)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition({
            coords: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
        },
        (error) => updateMapConfig({ center: initialLocation })
      );
  }, []);

  useEffect(() => {
    if (userPosition && googleServices && appType === appTypes.END_USER)
      onMyLocationClick();
  }, [userPosition, googleServices]);

  const onMyLocationClick = () => {
    if (userPosition) {
      const center = {
        lat: userPosition.coords.latitude,
        lng: userPosition.coords.longitude,
      };

      updateMapConfig({
        center,
        zoom: 18,
      });

      if (googleServices.geocoder) {
        geocodePlace(center, googleServices).then((place) =>
          updateRoutes({
            origin: mapGooglePlaceToTakasiAddress(place),
          })
        );
      }
    }
  };

  const getPlaceDetails = (address) => {
    return new Promise((resolve, reject) => {
      googleServices.places.getDetails(
        {
          placeId: address.place_id,
          fields: ["name", "address_component", "geometry"],
        },
        (place, status) => {
          if (status === "OK") {
            resolve({
              ...place,
              formatted_address: address.description,
              id: address.place_id,
            });
          }
        }
      );
    });
  };

  const getPlace = (address) =>
    address.place_id
      ? getPlaceDetails(address)
      : geocodePlace(address.address.location, googleServices);

  const onRouteClick = (address) => {
    getPlace(address).then((place) => {
      const nextRoute = mapGooglePlaceToTakasiAddress({
        ...place,
        place_id: address.place_id,
        id: address.id,
      });

      onRouteChange({
        key: routes.active,
        ...routes[routes.active],
        ...nextRoute,
        ...(address.created_at && !address.isPoi ? { isFavourite: true } : {}),
      });

      updateSearching({ isSearching: false });
    });
  };

  const onMapPan = ({ center, zoom }) => {
    if (shouldCenterOfMapBeUpdated(center))
      updateMapConfig({
        center,
        zoom,
      });
  };

  const saveOriginAddressAsFavourite = () => {
    setShowModal(true);
  };

  const shouldCenterOfMapBeUpdated = (center) =>
    center.lat?.toPrecision(COORDINATES_PRECISION_DECIMAL_POINT) !==
      mapConfig.center.lat?.toPrecision(COORDINATES_PRECISION_DECIMAL_POINT) ||
    center.lng?.toPrecision(COORDINATES_PRECISION_DECIMAL_POINT) !==
      mapConfig.center.lng?.toPrecision(COORDINATES_PRECISION_DECIMAL_POINT);

  return (
    <Container ref={mainContainerRef}>
      {searching.isSearching && (
        <Overlay>
          {!isMobile && appType !== appTypes.HOTEL && (
            <DirectionsTabsContainer>
              <DirectionsTabs
                onTabClick={(mode) => updateSearching({ mode })}
              />
            </DirectionsTabsContainer>
          )}
        </Overlay>
      )}
      <RelativeContainer>
        <AddressFinder
          {...{
            isMobile,
            searching,
            updateSearching,
            routes,
            onRouteChange,
            saveOriginAddressAsFavourite,
            googleServices,
            onRouteClick,
            mapConfig,
            updateMapConfig,
            userPosition,
            updateRoutes,
          }}
        />
        <MapContainer>
          <CustomGoogleMap
            routes={routes}
            mapConfig={mapConfig}
            onLoad={onMapLoad}
            onMapPan={onMapPan}
            onMyLocationClick={onMyLocationClick}
          />
        </MapContainer>
      </RelativeContainer>
      {showModal && (
        <FavouriteAddressModal
          address={routes.origin}
          companyId={1}
          onCancelClick={() => setShowModal(false)}
          onConfirmClick={(data) => {
            setShowModal(false);
            updateRoutes({
              origin: {
                ...routes.origin,
                id: data.id,
                favouriteProps: data,
                isFavourite: true,
              },
            });
          }}
        />
      )}
    </Container>
  );
};

export default Map;
