import React from "react";
import styled from "styled-components";
import { Tabs, Tab } from "bluejay-ui";

const CustomTabs = styled(Tabs)`
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
`;

const CustomTab = styled(Tab)`
  color: ${({ color }) => color};
  text-transform: none;
`;

const TabsSelector = ({ tabs = [], style, className }) => (
  <CustomTabs {...{ style, className }}>
    {tabs.map((props, key) => (
      <CustomTab key={key} {...props} />
    ))}
  </CustomTabs>
);

export default TabsSelector;
