import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { responsive } from "bluejay-ui";
import { Map } from "components";
import { Menu } from "ui";
import {
  getAgreedPriceTripEstimation,
  getTaximeterTripEstimation,
} from "utils/requests";
import {
  GoogleServices,
  mapStates,
  isBigMapRoute,
  isInvisibleMapRoute,
} from "utils/map";
import { BookingContext } from "contexts";
const modes = {
  INITIAL: {
    name: "INITIAL",
  },
};

const { mediaQuery, useMedia, breakpoints } = responsive;

const AuthPage = ({ user, children }) => {
  const isMobile = useMedia([breakpoints.TABLET], [false], true);
  const location = useLocation();
  const {
    booking,
    toogleTripSpecification,
    toogleTripSpecificationAndUnallowPriceTypeSelected,
    updateBooking,
    bookingScreensErrorState,
    updateBookingScreensErrorState,
  } = useContext(BookingContext);

  const [googleServices, setGoogleServices] = useState({});
  const [routes, setRoutes] = useState({
    active: "origin",
    origin: { name: "" },
    destination: { name: "" },
    disabled: false,
  });
  const [mapConfig, setMapConfig] = useState({
    center: { lat: undefined, lng: undefined },
    zoom: 18,
    height: "70vh",
    mode: mapStates.NORMAL,
  });

  const allRequiredEstimationPreferencesAreFilled = () =>
    booking.requiredEstimationPreferences &&
    booking.requiredEstimationPreferences.length
      ? booking.requiredEstimationPreferences?.every(
          (preference) =>
            preference && preference.value && preference.value !== ""
        )
      : true;

  const isEstimationPossible = () =>
    routes.origin?.location &&
    routes.destination?.location &&
    booking.company?.id &&
    allRequiredEstimationPreferencesAreFilled();

  useEffect(() => {
    updateMapConfig({
      mode: isMobile
        ? isInvisibleMapRoute(location.pathname)
          ? mapStates.INVISIBLE
          : mapStates.NORMAL
        : isBigMapRoute(location.pathname)
        ? mapStates.BIG
        : mapStates.NORMAL,
    });
  }, [location, isMobile]);

  useEffect(() => {
    const cleanErrorMessages =
      bookingScreensErrorState.mode?.name !== "INITIAL";
    if (cleanErrorMessages)
      updateBookingScreensErrorState({ mode: modes.INITIAL });
    const selectedSpecification = booking.company?.specifications.find(
      ({ checked }) => checked
    );
    if (isEstimationPossible() && selectedSpecification) {
      if (routes.estimation) {
        updateRoutes({ estimationFinished: false });
      }
      const isMaxOrFixedPriceAllowed =
        booking.company.companyAllowsMaxAgreedPrice ||
        booking.company.companyAllowsFixedAgreedPrice;
      if (
        isMaxOrFixedPriceAllowed &&
        selectedSpecification.value !== "taximeter"
      ) {
        checkTollsAndGetEstimationForAgreedPrice();
      } else {
        if (!routes.estimationFinished) {
          //evito bucles innecesarios
          getTaximeterEstimation();
        } else {
          updateRoutes({ estimationFinished: true });
        }
      }
    } else {
      updateRoutes({ estimation: null, estimationFinished: false });
    }
  }, [
    routes.origin?.location,
    routes.destination?.location,
    booking.requiredEstimationPreferences,
    booking.company.preferences,
    booking.company.specifications,
    booking.company.companyAllowsMaxAgreedPrice,
    booking.company.companyAllowsFixedAgreedPrice,
  ]);

  const checkTollsAndGetEstimationForAgreedPrice = () => {
    getTripPriceEstimation({
      includesTolls: true,
    })
      .then((data) => {
        dealWithSuccessfulEstimationRequest(data);
      })
      .catch((error) => {
        const selectedPriceTypeOption = booking.company?.specifications.find(
          ({ checked }) => checked
        ).value;
        if (shouldRequestBeReExecutedForTheOtherTypeOfPrice(error.code)) {
          const newTripSpec =
            selectedPriceTypeOption === "maxPrice"
              ? 0
              : selectedPriceTypeOption === "fixedPrice"
              ? 2
              : 1;
          toogleTripSpecificationAndUnallowPriceTypeSelected(
            newTripSpec,
            selectedPriceTypeOption
          );
        } else {
          toogleTripSpecificationAndUnallowPriceTypeSelected(
            0,
            selectedPriceTypeOption
          );
        }
      });
  };

  const dealWithSuccessfulEstimationRequest = (data) => {
    if (data.includesTolls)
      getTripPriceEstimationWithoutTollsAndStoreBoth(data);
    else
      updateRoutes({
        estimation: data,
        estimationWithTolls: null,
        estimationFinished: true,
      });
  };

  const getTripPriceEstimation = ({
    agreedPriceType = booking.company?.specifications.find(
      ({ checked }) => checked
    ).value,
    includesTolls = booking.company.includesTolls,
  }) => {
    const numberOfBags = booking.requiredEstimationPreferences.find(
      ({ name }) => name === "isBagsMandatory"
    );
    const numberOfPassengers = booking.requiredEstimationPreferences.find(
      ({ name }) => name === "isPassengersMandatory"
    );

    let estimationAgreedPriceType = "taximeter";
    switch (agreedPriceType) {
      case "taximeter":
        estimationAgreedPriceType = booking.company.companyAllowsMaxAgreedPrice
          ? "maxPrice"
          : booking.company.companyAllowsFixedAgreedPrice
          ? "fixedPrice"
          : "taximeter";
        break;
      case "maxPrice":
        estimationAgreedPriceType = booking.company.companyAllowsMaxAgreedPrice
          ? "maxPrice"
          : booking.company.companyAllowsFixedAgreedPrice
          ? "fixedPrice"
          : "taximeter";
        break;
      case "fixedPrice":
        estimationAgreedPriceType = booking.company
          .companyAllowsFixedAgreedPrice
          ? "fixedPrice"
          : booking.company.companyAllowsMaxAgreedPrice
          ? "maxPrice"
          : "taximeter";
        break;
    }

    return (
      estimationAgreedPriceType === "maxPrice" ||
        estimationAgreedPriceType === "fixedPrice"
        ? getAgreedPriceTripEstimation
        : getTaximeterTripEstimation
    )({
      pickupAddress: { location: routes.origin.location },
      destinationAddress: { location: routes.destination.location },
      companyId: booking.company.id,
      agreedPriceType: estimationAgreedPriceType,
      ...(estimationAgreedPriceType === "maxPrice" ||
      estimationAgreedPriceType === "fixedPrice"
        ? { bookingType: 0, includesTolls }
        : {}),
      ...(numberOfBags
        ? {
            numberOfBags: Number(numberOfBags.value),
          }
        : {}),
      ...(numberOfPassengers
        ? {
            numberOfPassengers: Number(numberOfPassengers.value),
          }
        : {}),
    })
      .then(({ data }) => Promise.resolve(data))
      .catch(({ error }) => {
        if (
          (error.code === 6001 || error.code === 6002) &&
          estimationAgreedPriceType != "taximeter"
        )
          return Promise.reject(error);
        updateRoutes({
          estimationFinished: true,
          estimationWithTolls: null,
          estimation: null,
        });
      });
  };

  const shouldRequestBeReExecutedForTheOtherTypeOfPrice = (errorCode) =>
    booking.company.companyAllowsMaxAgreedPrice &&
    booking.company.companyAllowsFixedAgreedPrice &&
    errorCode === 6002;

  const getTaximeterEstimation = () => {
    return getTripPriceEstimation({
      agreedPriceType: "taximeter",
    })
      .then((data) => {
        updateRoutes({
          estimation: data,
          estimationWithTolls: null,
          estimationFinished: true,
        });
        updateBooking({
          company: {
            ...booking.company,
            includesTolls: false,
          },
        });
      })
      .catch(() => {
        updateRoutes({
          estimation: null,
          estimationWithTolls: null,
          estimationFinished: true,
        });
        updateBooking({
          company: {
            ...booking.company,
            includesTolls: false,
          },
        });
      });
  };

  const getTripPriceEstimationWithoutTollsAndStoreBoth = (data) => {
    getTripPriceEstimation({
      includesTolls: false,
    }).then((dataWithoutTolls) => {
      updateRoutes({
        estimation: dataWithoutTolls,
        estimationWithTolls: data,
        estimationFinished: true,
      });
    });
  };

  const onMapLoad = ({ map, maps }) => {
    setGoogleServices(GoogleServices({ map, maps }));
  };

  const updateRoutes = (nextRoutes) =>
    setRoutes((routes) => ({ ...routes, ...nextRoutes }));

  const updateMapConfig = (nextMapConfig) => {
    setMapConfig((mapConfig) => ({ ...mapConfig, ...nextMapConfig }));
  };

  const onRouteChange = ({ key, ...props }) => {
    updateRoutes({
      [key]: props,
      active: key,
    });
  };

  const setRoutesEnabled = (enabled) =>
    updateRoutes({
      ...routes,
      disabled: !enabled,
    });

  const resetRoutes = () => {
    setRoutes({
      origin: routes.origin,
      active: "origin",
      destination: { name: "" },
      disabled: false,
    });
  };

  return (
    <Container>
      {mapConfig.mode !== mapStates.INVISIBLE && (
        <MapContainer height={mapConfig.height} mode={mapConfig.mode}>
          <Map
            googleServices={googleServices}
            mapConfig={mapConfig}
            updateMapConfig={updateMapConfig}
            routes={routes}
            updateRoutes={updateRoutes}
            onRouteChange={onRouteChange}
            onMapLoad={onMapLoad}
          />
        </MapContainer>
      )}
      <Main>
        {children({
          mapConfig,
          routes,
          resetRoutes,
          setRoutesEnabled,
          updateMapConfig,
          updateRoutes,
        })}
      </Main>
      {!isMobile && <Menu user={user} />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;

  ${mediaQuery.TABLET`
    flex-direction: row;
    height: 100vh;
  `}
`;

const MapContainer = styled.div`
  width: 100%;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

  ${({ height = "70vh", mode }) => `
    height: ${height};
    ${mediaQuery.TABLET`
      height: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
      width: ${mode === mapStates.BIG ? "70%" : "30%"};
    `}
  `}

  transition: width 1s;
  overflow: hidden;
`;

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  height: calc(100% - 290px);
  padding-top: 60px;
  padding-bottom: 100px;

  ${mediaQuery.TABLET`
    padding: 90px 70px;
    padding-top: 200px;
    width: auto;
  `}
`;

export default AuthPage;
