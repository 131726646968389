import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UnAuthForm } from "components/";
import { errors } from "utils/modesErrors";
import {
  registerRequest,
  doRequestAndLocalLogin,
  resendSMSCode,
} from "utils/requests";
import handleErrors from "utils/handleErrors";
import { isValidPhone } from "utils/validations";

const Register = ({ login }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(modes.INITIAL);

  const onSubmit = (credentials) => {
    if (
      !credentials.confirmPassword ||
      credentials.confirmPassword !== credentials.password
    ) {
      setMode(modes.NOT_EQUAL);
    } else if (!isValidPhone(credentials.mobileNumber)) {
      setMode(modes.NOT_VALID_PHONE);
    } else {
      return doRegister({ credentials, mode, setMode, login });
    }
  };

  const resetMode = () => {
    if (mode !== modes.INITIAL) setMode(modes.INITIAL);
  };

  return (
    <UnAuthForm
      onSubmit={onSubmit}
      resetMode={resetMode}
      title={t("register.title")}
      conditions={t("register.acceptConditions")}
      submitText={t(mode.submitText)}
      secondaryText={t("general.alreadyHaveAnAccount")}
      secondaryLink={t("general.signin")}
      secondaryTo={"/"}
      hideTitleAndLink={mode === modes.ACTIVATION_AUTO}
      fields={
        mode === modes.INITIAL ||
        mode === modes.WEAK_PASSWORD ||
        mode === modes.NOT_EQUAL ||
        mode === modes.NOT_VALID_PHONE ||
        mode === modes.CLIENT_EXISTS ||
        mode === modes.USER_ALREADY_EXISTS ||
        mode === modes.SERVER_OFFLINE
          ? [
              {
                label: t("general.firstName"),
                placeholder: t("general.firstName"),
                type: "text",
                name: "firstName",
                id: "firstName",
              },
              {
                label: t("general.lastName"),
                placeholder: t("general.lastName"),
                type: "text",
                name: "lastName",
                id: "lastName",
              },
              {
                label: t("general.mobileNumber"),
                placeholder: t("general.mobileNumber"),
                type: "number",
                name: "mobileNumber",
                id: "mobileNumber",
              },
              {
                label: t("general.email"),
                placeholder: t("general.email"),
                type: "email",
                name: "email",
                id: "email",
              },
              {
                label: t("general.password"),
                placeholder: t("general.password"),
                type: "password",
                name: "password",
                id: "password",
              },
              {
                label: t("general.confirmPassword"),
                placeholder: t("general.confirmPassword"),
                type: "password",
                name: "confirmPassword",
                id: "confirmPassword",
              },
            ]
          : mode === modes.ACTIVATION_PENDING_SMS ||
            mode === modes.EXPIRED_TOKEN
          ? [
              {
                label: t("general.smsCode"),
                placeholder: t("general.smsCode"),
                type: "text",
                name: "smsCode",
                id: "smsCode",
              },
            ]
          : []
      }
      message={mode.message}
    />
  );
};

function doRegister({ credentials, mode, setMode, login }) {
  return registerRequest({ url: mode.url, credentials })
    .then(({ data: { activationMethod } }) => {
      switch (activationMethod) {
        case "sms":
          setMode(modes.ACTIVATION_PENDING_SMS);
          break;
        case "email":
          setMode(modes.ACTIVATION_PENDING_EMAIL);
          break;
        case "auto":
          setMode(modes.ACTIVATION_AUTO);
          setTimeout(
            () =>
              doRequestAndLocalLogin({
                credentials,
                localLogin: login,
                handleErrorsParams: { modes, setMode },
              }),
            3000
          );
          break;
      }
    })
    .catch((catchElement) =>
      customHandleErrors({ ...catchElement, setMode, credentials })
    );
}

function customHandleErrors({ error, setMode = () => {}, credentials }) {
  const nextMode = handleErrors({ error, modes, setMode });
  if (nextMode === modes.EXPIRED_TOKEN) {
    resendSMSCode(credentials);
  }
}

const modes = {
  INITIAL: {
    name: "INITIAL",
    url: "clients/v1/users",
    submitText: "general.signup",
  },
  ACTIVATION_PENDING_SMS: {
    name: "ACTIVATION_PENDING_SMS",
    url: "clients/v1/users/activate",
    submitText: "general.activate",
    message: {
      color: "warning",
      translation: "register.accountActivationPendingSMS",
    },
  },
  ACTIVATION_PENDING_EMAIL: {
    name: "ACTIVATION_PENDING_EMAIL",
    message: {
      color: "warning",
      translation: "register.accountActivationPendingEMAIL",
    },
  },
  ACTIVATION_AUTO: {
    name: "ACTIVATION_AUTO",
    message: {
      color: "primary",
      translation: "register.accountHasBeenAutomaticallyActivated",
    },
  },
  ACTIVATION_SUCCESS: {
    name: "ACTIVATION_SUCCESS",
    message: {
      color: "danger",
      translation: "register.accountHasBeenActivated",
    },
  },
  EXPIRED_TOKEN: {
    name: "EXPIRED_TOKEN",
    url: "clients/v1/users/activate",
    errorCode: 4106,
    submitText: "general.activate",
    message: {
      color: "danger",
      translation: "register.errors.expiredToken",
    },
  },
  WEAK_PASSWORD: {
    name: "WEAK_PASSWORD",
    url: "clients/v1/users",
    errorCode: 4108,
    submitText: "general.signup",
    message: {
      color: "danger",
      translation: "register.errors.weakPassword",
    },
  },
  NOT_EQUAL: {
    name: "NOT_EQUAL",
    url: "clients/v1/users",
    submitText: "general.signup",
    message: {
      color: "danger",
      translation: "register.errors.not_equal",
    },
    errorCode: 4107,
  },
  NOT_VALID_PHONE: {
    name: "NOT_VALID_PHONE",
    submitText: "general.signup",
    message: {
      color: "danger",
      translation: "register.errors.not_valid_phone",
    },
  },
  SERVER_OFFLINE: errors.serverOffline,
  CLIENT_EXISTS: errors.clientExists,
  USER_ALREADY_EXISTS: errors.userAlreadyExists,
};

export default Register;
