import { fetch } from "whatwg-fetch";
import { errors } from "../utils/modesErrors"
import clientTypes from "../utils/app/clientTypes.json"

let isRefreshingToken = false;

const mustWaitForANewToken = () => {
  return new Promise((resolve, reject) => {
    if (isRefreshingToken) {
      setTimeout(() => {
        resolve();
      }, 500);
    } else {
      resolve();
    }
  });
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("isLoggedIn");
  window.location = "/";
};

const request = (url, options = {}) => {
  const token = localStorage.getItem("token");
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const headers = {
    "x-application-id": process.env.REACT_APP_APPLICATION_ID,
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    // line below lets the backend know that these requests are from pidetaxi web 
    // which means that the user trying to login might be a hotel user and in the web he is allowed to login
    // without this only regular users will be able to login
    ...(loggedInUser?.type === clientTypes.hotel || !loggedInUser ? {"x-origin-id": clientTypes.hotel} : {}),
  };

  return mustWaitForANewToken().then(() =>
    fetch(process.env.REACT_APP_API_URL + url, {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }).then((res) => {
      switch (res.status) {
        case 200:
          return res.json();
        case 403:
          if (isRefreshingToken) {
            return request(url, options);
          } else {
            return res.json().then(error => {
              const shouldWeRefreshTkn = tokenShouldBeRefreshed(error)
              if (shouldWeRefreshTkn) {
                isRefreshingToken = true;
                return fetch(
                  `${process.env.REACT_APP_API_URL}clients/v1/users/refreshToken`,
                  {
                    method: "POST",
                    headers,
                    body: JSON.stringify({
                      refreshToken: localStorage.getItem("refreshToken"),
                    }),
                  }
                )
                  .then((res) => {
                    switch (res.status) {
                      case 200:
                        return res.json();
                      case 400:
                      case 403:
                      case 500:
                        logout();
                        return Promise.reject();
                      default:
                        return Promise.resolve();
                    }
                  })
                  .then((res) => {
                    localStorage.setItem("token", res.data.access_token);
                    localStorage.setItem(
                      "refreshToken",
                      res.data.refresh_token
                    );
                    isRefreshingToken = false;
                    return request(url, options);
                  });
              } else {
                return genericBehaviourForErrorHandling(res, error);
              }
            })
          }

        default:
          return res
            .json()
            .then((error) => genericBehaviourForErrorHandling(res, error));
      }
    })
  );
};

const tokenShouldBeRefreshed = (errorResponse) =>
  errorResponse.code !== errors.userInactive.errorCode;

const genericBehaviourForErrorHandling = (res, error) => Promise.reject({ status: res.status, error })

export default request;
