import styled from "styled-components";
import { Label } from "bluejay-ui";

const CustomLabel = styled(Label)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;
  text-transform: none;
`;

export default CustomLabel;
