const primary = "#188BC7";
const secondary = "#004869";
const danger = "#FF2E00";
const _default = "#9B9B9B";
const warning = "#FF9900";

const buttonsColors = {
  primary: {
    background: primary,
    text: "#fff",
  },
  secondary: {
    background: secondary,
    text: "#247BC4",
  },
  tertiary: {
    background: "#fff",
    text: primary,
  },
  quaternary: {
    background: "#fff",
    text: secondary,
  },
  default: {
    background: _default,
    text: "white",
  },
  warning: {
    background: warning,
    text: "white",
  },
};

const theme = {
  primary,
  secondary,
  danger,
  warning,
  default: _default,
  button: buttonsColors,
  link: {
    ...buttonsColors,
    primary: {
      text: primary,
    },
    default: {
      text: _default,
    },
  },
  borderRadius: "10px",
};

export default theme;
