import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import Promise from "bluebird";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, Button, responsive, Modal } from "bluejay-ui";
import { Book, BookSummary, BookSchedule, Trip, MyTrips } from "pages";
import {
  companiesRequest,
  bookTripRequest,
  companyPOIsRequest,
} from "utils/requests";
import {
  mapBookingToTrip,
  isActiveBooking,
  companyAllowsMaxAgreedPrice,
  companyAllowsFixedAgreedPrice,
  getClientIpAddress,
} from "utils/booking";
import { appTypes, getAppType } from "utils/app";
import { buildOptionsArrayGivenMinAndMaxValues } from "utils/arrays";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { BookingContext } from "contexts";
import { Label, Navigation } from "ui";
import { errors } from "utils/modesErrors";
import handleErrors from "utils/handleErrors";

const { useMedia, breakpoints } = responsive;

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
  NO_CAR_COVERAGE: errors.noCarCoverage,
  INVALID_BOOKING_DATE: errors.invalidBookingDate,
  CLIENT_BLOCKED: errors.clientBlocked,
  INVALID_SUBSCRIBER_DATA: errors.invalidSubscriberData,
  DESTINATION_IS_MANDATORY: errors.destinationIsMandatory,
  SCHEDULED_TRIP_NOT_ALLOWED: errors.scheduledTripNotAllowed,
  SUBSCRIBER_ACCOUNT_NOT_FOUND: errors.subscriberNotFound,
  SUBSCRIBER_ACCOUNT_NOT_ACTIVE: errors.subscriberAccountNotActive,
  NUMBER_OF_BAGS_IS_MANDATORY: errors.numberOfBagsIsMandatory,
  NUMBER_OF_PASSENGERS_IS_MANDATORY: errors.numberOfPassengersIsMandatory,
  TIME_ZONE_CONVERSION_ERROR: errors.timezoneConversionError,
  SIMULTANEOUS_TRIP_ERROR: errors.simultaneousTripError,
  VEHICLE_NOT_AVAILABLE: errors.vehicleNotAvailable,
  INVALID_PICKUP_TOO_CLOSE_TO_STAND: errors.invalidPickupTooCloseToStand,
  NO_CAR_MEET_REQUIREMENTS: errors.noCarMeetRequirements,
};

const zoneConfigOptions = {
  isBagsMandatory: { minValue: 0, maxValue: 10 },
  isPassengersMandatory: { minValue: 1, maxValue: 6 },
};

const BookingScreens = (props) => {
  const { mapConfig, routes, resetRoutes } = props;
  const appType = getAppType();

  const { t } = useTranslation();
  const history = useHistory();
  const {
    booking,
    bookingScreensErrorState,
    updateBookingScreensErrorState,
    updateBooking,
    toogleCompanyPreference,
    toogleTripSpecification,
    toogleEstimationincludesTolls,
    resetBooking,
  } = useContext(BookingContext);

  const [clientIpAddress, setClientIpAddress] = useState("");

  useEffect(() => {
    getClientIpAddress()
      .then((ip) => setClientIpAddress(ip))
      .catch((error) => console.error("Error al obtener la IP:", error));
  }, []);

  const showResetButton = useMedia([breakpoints.TABLET], [true], false);
  const trip = mapBookingToTrip({ booking, routes, clientIpAddress });

  const [showResetFiltersModal, setShowResetFiltersModal] = useState(false);

  const isErrorMode = bookingScreensErrorState.mode?.name != "INITIAL";

  const [isBooking, setIsBooking] = useState(false);

  useEffect(() => {
    if (
      mapConfig.center.lat &&
      mapConfig.center.lng &&
      ((booking.company && booking.company.preferences.length === 0) ||
        appType === appTypes.END_USER)
    ) {
      if (routes.active === "origin") {
        updateBooking({
          company: {
            ...booking.company,
            isDestinationMandatory: true,
            canScheduleTrip: false,
          },
        });
        companiesRequest(mapConfig.center)
          .then(({ data }) => {
            const doCompanyAllowMaxAgreedPrice =
              !!companyAllowsMaxAgreedPrice(data);
            const doCompanyAllowFixedAgreedPrice =
              !!companyAllowsFixedAgreedPrice(data);

            const { isBagsMandatory, isPassengersMandatory, paymentMethods } =
              data.zoneConfig;

            const requiredZoneConfig = Object.keys({
              isBagsMandatory,
              isPassengersMandatory,
              paymentMethods,
            }).filter((preference) => data.zoneConfig[preference] === true);

            const formattedRequiredZoneConfig =
              requiredZoneConfig && requiredZoneConfig.length
                ? requiredZoneConfig.map((requiredPreference) => ({
                    name: requiredPreference,
                    value: "",
                    options: buildOptionsArrayGivenMinAndMaxValues(
                      zoneConfigOptions[requiredPreference]
                    ),
                  }))
                : [];

            let companySpecs = [
              {
                value: "taximeter",
                checked:
                  !doCompanyAllowFixedAgreedPrice &&
                  !doCompanyAllowMaxAgreedPrice,
              },
              {
                value: "fixedPrice",
                checked:
                  doCompanyAllowFixedAgreedPrice &&
                  !doCompanyAllowMaxAgreedPrice,
              },
              {
                value: "maxPrice",
                checked:
                  doCompanyAllowMaxAgreedPrice &&
                  !doCompanyAllowFixedAgreedPrice,
              },
            ];

            return companyPOIsRequest({ companyId: data.company?.id }).then(
              (companyPOIs) => {
                updateBooking({
                  company: {
                    ...booking.company,
                    ...data.company,
                    specifications: filterCompanySpecs(
                      companySpecs,
                      doCompanyAllowMaxAgreedPrice,
                      doCompanyAllowFixedAgreedPrice
                    ),
                    companyAllowsMaxAgreedPrice: doCompanyAllowMaxAgreedPrice,
                    companyAllowsFixedAgreedPrice:
                      doCompanyAllowFixedAgreedPrice,
                    companyAllowsMaxAgreedPriceOriginalValue:
                      doCompanyAllowMaxAgreedPrice,
                    companyAllowsFixedAgreedPriceOriginalValue:
                      doCompanyAllowFixedAgreedPrice,
                    pois: companyPOIs.data?.result,
                  },
                  requiredEstimationPreferences: formattedRequiredZoneConfig,
                });
              }
            );
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else if (routes.active === "destination") {
        const companyAllowsMaxAgreedPriceOriginalValue =
          booking.company.companyAllowsMaxAgreedPriceOriginalValue;
        const companyAllowsFixedAgreedPriceOriginalValue =
          booking.company.companyAllowsFixedAgreedPriceOriginalValue;
        let companySpecs = [
          {
            value: "taximeter",
            checked:
              !companyAllowsFixedAgreedPriceOriginalValue &&
              !companyAllowsMaxAgreedPriceOriginalValue,
          },
          {
            value: "fixedPrice",
            checked:
              companyAllowsFixedAgreedPriceOriginalValue &&
              !companyAllowsMaxAgreedPriceOriginalValue,
          },
          {
            value: "maxPrice",
            checked:
              companyAllowsMaxAgreedPriceOriginalValue &&
              !companyAllowsFixedAgreedPriceOriginalValue,
          },
        ];
        updateBooking({
          company: {
            ...booking.company,
            specifications: filterCompanySpecs(
              companySpecs,
              companyAllowsMaxAgreedPriceOriginalValue,
              companyAllowsFixedAgreedPriceOriginalValue
            ),
            companyAllowsMaxAgreedPrice:
              companyAllowsMaxAgreedPriceOriginalValue,
            companyAllowsFixedAgreedPrice:
              companyAllowsFixedAgreedPriceOriginalValue,
          },
        });
      }
    }
  }, [mapConfig.center.lat, mapConfig.center.lng]);

  const filterCompanySpecs = (
    companySpecs,
    doCompanyAllowMaxAgreedPrice,
    doCompanyAllowFixedAgreedPrice
  ) => {
    if (doCompanyAllowMaxAgreedPrice && !doCompanyAllowFixedAgreedPrice) {
      return companySpecs.filter(
        ({ value }) => value === "taximeter" || value === "maxPrice"
      );
    } else if (
      !doCompanyAllowMaxAgreedPrice &&
      doCompanyAllowFixedAgreedPrice
    ) {
      return companySpecs.filter(
        ({ value }) => value === "taximeter" || value === "fixedPrice"
      );
    } else {
      return companySpecs.filter(({ value }) => value === "taximeter");
    }
  };

  const onBookNowClick = async () => {
    if (isBooking) return;
    setIsBooking(true);

    try {
      const tripInstances = await Promise.all(
        Array.from(Array(booking.taxisCount)).map(() => bookTripRequest(trip))
      );
      resetBooking();
      return tripInstances;
    } catch (e) {
      return handleErrors({
        ...e,
        modes,
        setMode: (mode) => updateBookingScreensErrorState({ mode }),
      });
    } finally {
      setIsBooking(false);
    }
  };

  const onResetButtonClick = () => {
    resetRoutes();
    updateBooking({
      company: {
        ...booking.company,
        preferences: booking.company.preferences.map((preference) => ({
          ...preference,
          checked: false,
        })),
      },
      remarks: "",
      taxisCount: 1,
    });
    history.push("/");
    setShowResetFiltersModal(false);
  };

  const localProps = {
    booking,
    updateBooking,
    trip,
    toogleCompanyPreference,
    toogleTripSpecification,
    toogleEstimationincludesTolls,
    onBookNowClick,
  };

  return booking.company ? (
    <>
      {showResetButton && (
        <ResetButtonContainer show={isActiveBooking(booking, routes)}>
          <ResetButton
            icon={CloseIcon}
            onClick={() => setShowResetFiltersModal(true)}
          >
            {t("book.newBook")}
          </ResetButton>
        </ResetButtonContainer>
      )}

      {isErrorMode && (
        <Text color={bookingScreensErrorState.mode.message.color}>
          {t(bookingScreensErrorState.mode.message.translation)}
        </Text>
      )}

      {showResetFiltersModal && (
        <Modal Title={Label} title={t("book.resetFiltersModalMessage")}>
          <Navigation
            leftButton={{
              children: t("general.no"),
              onClick: () => setShowResetFiltersModal(false),
            }}
            rightButton={{
              children: t("general.yes"),
              color: "warning",
              onClick: onResetButtonClick,
            }}
          />
        </Modal>
      )}
      <Route path="/" exact>
        <Book {...props} {...localProps} />
      </Route>
      <Route path="/schedule">
        <BookSchedule {...props} {...localProps} />
      </Route>
      <Route path="/summary">
        <BookSummary {...props} {...localProps} />
      </Route>
      <Route path="/trips" exact>
        <MyTrips {...props} />
      </Route>
      <Route path="/trips/:id">
        <Trip {...props} />
      </Route>
      <Redirect to="/" />
    </>
  ) : (
    <NoCompanyContainer>
      <Text>{t("book.noCompany")}</Text>
    </NoCompanyContainer>
  );
};

const ResetButtonContainer = styled.div`
  position: absolute;
  top: -60px;
  transition: transform 0.5s;
  ${({ show = false }) => (show ? "transform: translateY(100px)" : "")};
`;

const ResetButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: row;
  background: #ff9900;
  border-radius: 100px;
  width: 200px;
  height: 44px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
`;

const NoCompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BookingScreens;
