import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Text } from "bluejay-ui";
import { Navigation, Input } from "ui";
import { addAddressToFavourites, getFavouriteAddresses } from "utils/requests";
import handleErrors from "utils/handleErrors";
import { errors } from "utils/modesErrors";

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
  FAVOURITE_PLACE_LIMIT_REACHED: errors.favoritePlaceLimitReached,
  FAVOURITE_PLACE_NOT_UNIQUE: errors.favoritePlaceNameNotUnique,
  SERVER_OFFLINE: errors.serverOffline,
  MANDATORY_FIELD_MISSING: errors.mandatoryFieldMissing,
  INVALID_DATA: errors.invalidData,
};

const FavouriteAddressModal = ({
  address = {},
  onCancelClick = () => {},
  onConfirmClick = () => {},
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: "",
    isDefault: false,
    mode: modes.INITIAL,
  });

  const isStateOnErrorMode = state.mode !== modes.INITIAL;

  const onInputChange = ({ target: { value } }) => {
    if (isStateOnErrorMode) {
      // reset state
      updateState({ mode: modes.INITIAL });
    }
    updateState({ name: value });
  };

  const updateState = (nextState) =>
    setState((currentState) => ({ ...currentState, ...nextState }));

  const onLocalConfirmClick = async () => {
    if (state.mode === modes.INITIAL) {
      try {
        const total = await getFavouriteAddresses();
        const { data } = total;
        const favouritePlacesLimit = 5;

        // Check if the name is not empty and the total number of favourites is within the limit
        if (
          state.name.replace(/\s/g, "").length &&
          data.length < favouritePlacesLimit
        ) {
          // Proceed
          addAddressToFavourites({
            address,
            ...state,
          })
            .then(({ data }) => onConfirmClick(data))
            .catch((catchElement) => {
              handleErrors({
                ...catchElement,
                modes,
                setMode: (mode) => updateState({ mode }),
              });
            });
        } else if (!state.name.replace(/\s/g, "").length) {
          updateState({ mode: modes.MANDATORY_FIELD_MISSING });
        } else {
          // limit is reached
          updateState({ mode: modes.FAVOURITE_PLACE_LIMIT_REACHED });
        }
      } catch (error) {
        console.error("Error fetching favourite addresses:", error);
      }
    }
  };

  return (
    <Modal title={t("favourites.addAddressAsFavourite")}>
      <Text as="p" color="default" style={{ marginBottom: 30 }}>
        {address.name}
      </Text>
      <Input
        style={{ marginBottom: 10 }}
        label={t("general.name")}
        name="name"
        id="name"
        value={state.name}
        onChange={onInputChange}
        autoComplete="off"
      />
      {state.mode !== modes.INITIAL ? (
        <Text color={state.mode.message.color} style={{ marginBottom: 20 }}>
          {t(state.mode.message.translation)}
        </Text>
      ) : (
        <div style={{ marginBottom: 40 }} />
      )}
      <Navigation
        leftButton={{
          children: t("actions.cancel"),
          onClick: onCancelClick,
        }}
        rightButton={{
          children: t("actions.save"),
          color: "primary",
          disabled: !state.length > 3,
          onClick: onLocalConfirmClick,
        }}
      />
    </Modal>
  );
};

export default FavouriteAddressModal;
