import { Button } from "bluejay-ui";
import styled from "styled-components";

const CustomButton = styled(Button)`
  font-weight: normal;

  &.progress {
    &:disabled {
      cursor: progress;
    }
  }
`;

export default CustomButton;
