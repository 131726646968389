export const appTypes = {
  HOTEL: "HOTEL",
  END_USER: "END_USER",
};

export const getAppType = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  let type;
  if (loggedInUser) {
    type = loggedInUser.type;
  }
  return type?.toUpperCase() === appTypes.HOTEL
    ? appTypes.HOTEL
    : appTypes.END_USER;
};
