import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TabsSelector from "./TabsSelector";
import ResponsiveContainer from "./ResponsiveContainer";
import { ReactComponent as MyTrips } from "images/directions.svg";
import { ReactComponent as Favourites } from "images/favourites.svg";
import { searchModes } from "utils/map";

const TabsContainer = styled(ResponsiveContainer)`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

const CustomTabsSelector = styled(TabsSelector)`
  display: flex;
  justify-content: center;
`;

const DirectionsTabs = ({ onTabClick = () => {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <TabsContainer>
      <CustomTabsSelector
        {...props}
        tabs={[
          {
            color: "secondary",
            icon: MyTrips,
            onClick: () => onTabClick(searchModes.SUGGEST),
            children: t("general.directions"),
            "data-testid": "suggestions-tab",
          },
          {
            color: "secondary",
            icon: Favourites,
            onClick: () => onTabClick(searchModes.FAVOURITES),
            children: t("favourites.favourites"),
            "data-testid": "favourites-tab",
          },
        ]}
      />
    </TabsContainer>
  );
};

export default DirectionsTabs;
