import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { responsive } from "bluejay-ui";
import TabsSelector from "./TabsSelector";
import { status } from "utils/trip";
import { appTypes, getAppType } from "utils/app";

const { mediaQuery } = responsive;

const CustomTabsSelector = styled(TabsSelector)`
  position: static;

  div {
    display: flex;
  }

  ${mediaQuery.TABLET`
    position: absolute;
    top: 40px;
    right: 49%;
  `}
`;

const HistoryStatusSelector = ({ currentStatus, onChangeStatus }) => {
  const { t } = useTranslation();
  const [localAppType, setLocalAppType] = useState(null);

  useEffect(() => {
    const fetchAppType = () => {
      const type = getAppType();
      setLocalAppType(type);
    };

    fetchAppType();
  }, []);

  let filteredStatusKeys = Object.keys(status);
  if (localAppType === appTypes.HOTEL) {
    filteredStatusKeys = filteredStatusKeys.filter((s) => s !== "HISTORY");
  }

  const tabs = filteredStatusKeys.map((state) => ({
    color:
      currentStatus.name === status[state].name ? "tertiary" : "quaternary",
    onClick: () => onChangeStatus(status[state]),
    children: t("book.states." + state),
  }));

  return <CustomTabsSelector tabs={tabs} />;
};

export default HistoryStatusSelector;
