import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "bluejay-ui";
import { If, IfTextExist } from "ui";
import { dateFromTimestamp } from "utils/utils-date";
import { appTypes, getAppType } from "utils/app";
import { getWellNamedAddresses } from "utils/trip";
import { tripStatus as tripStatusEnum } from "takasi-library/src/enums";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

const LightText = styled(Text)`
  font-weight: 500;
  text-transform: none;
`;

const TripSummary = ({ tripSummaryResult, trip }) => {
  const appType = getAppType();
  const { t } = useTranslation();

  const { pickupName, destinationName } = getWellNamedAddresses(trip);
  const [tripStatus, setTripStatus] = useState(null);

  useEffect(() => {
    const fetchTripSummaryResult = async () => {
      try {
        if (
          trip.status === tripStatusEnum.integrationError ||
          trip.status === tripStatusEnum.finished ||
          trip.status === tripStatusEnum.canceled ||
          trip.status === tripStatusEnum.canceledBySystem
        ) {
          setTripStatus(
            trip.status === tripStatusEnum.integrationError
              ? tripStatusEnum.canceledBySystem
              : trip.status
          );
          return;
        }

        await tripSummaryResult(trip.id);
      } catch (error) {
        console.error("Error fetching updated trip data:", error);
      }
    };
    if (tripSummaryResult) {
      fetchTripSummaryResult();
    }
  }, [tripSummaryResult]);

  return (
    <If condition={trip}>
      <Container>
        <If condition={typeof trip.status !== "undefined"}>
          <LightText>
            {t("book.status")}:
            {t(`book.statusCodes.${tripStatus || trip.status}`)}
          </LightText>
        </If>
        <LightText>
          {t("book.origin")}: {pickupName}
        </LightText>
        {trip.destinationAddress && trip.destinationAddress.name && (
          <LightText>
            {t("book.destination")}:{" "}
            {trip.destinationAddress ? destinationName : ""}
          </LightText>
        )}
        {appType === appTypes.HOTEL && trip.taxisCount >= 1 && (
          <LightText data-testid="summary-taxis-count">
            {t("book.taxisCount")}: {trip.taxisCount}
          </LightText>
        )}
        <If condition={trip.bookingDate}>
          <LightText>
            {t("book.datetime")}:{" "}
            {trip.bookingDate
              ? dateFromTimestamp({ date: trip.bookingDate })
              : ""}
          </LightText>
        </If>
        <If condition={trip.agreedPriceType}>
          <LightText data-testid="agreedPriceOption">
            {t("book.options")}: {t(`book.${trip.agreedPriceType}`)}
          </LightText>
        </If>
        <If
          condition={
            trip.vehiclePreferences && trip.vehiclePreferences.length !== 0
          }
        >
          <LightText>{t("book.requirements")}:</LightText>
          {trip.vehiclePreferences &&
            trip.vehiclePreferences.map((option, key) => (
              <li
                key={key}
                style={{
                  marginLeft: "10px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <LightText as="span" style={{ fontWeight: 500 }}>
                  {option.name}
                </LightText>
              </li>
            ))}
        </If>
        <IfTextExist text={trip.remarks}>
          <LightText
            style={{
              marginTop: 20,
              fontWeight: 500,
            }}
          >
            {t("book.notes")}:
          </LightText>
          <LightText
            style={{
              marginTop: 10,
              margin: "0px 10px",
              fontWeight: 500,
            }}
          >
            {trip.remarks}
          </LightText>
        </IfTextExist>
        <If condition={trip.numberOfBags}>
          <LightText data-testid="required-isBagsMandatory">
            {t("zonePreferences.isBagsMandatory")}: {trip.numberOfBags}
          </LightText>
        </If>
        <If condition={trip.numberOfPassengers}>
          <LightText data-testid="required-isPassengersMandatory">
            {t("zonePreferences.isPassengersMandatory")}:{" "}
            {trip.numberOfPassengers}
          </LightText>
        </If>
      </Container>
    </If>
  );
};

export default TripSummary;
