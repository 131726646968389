import React from "react";
import styled from "styled-components";
import { Button } from "bluejay-ui";
import { useHistory } from "react-router-dom";
import { ReactComponent as HamburguerIcon } from "images/hamburguer.svg";
import { ReactComponent as MyTripsIcon } from "images/my-trips.svg";

const MenuContainer = styled.div`
  padding: 25px;
  padding-bottom: 0;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
`;

const RoundedButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

  span {
    margin: 0;
    width: 25px;
    height: 25px;
    box-shadow: none;
  }

  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const MobileMenu = () => {
  const history = useHistory();

  return (
    <MenuContainer>
      <RoundedButton
        onClick={() => history.push("/profile")}
        icon={HamburguerIcon}
        color="white"
      />
      <RoundedButton
        onClick={() => history.push("/trips")}
        icon={MyTripsIcon}
        color="white"
      />
    </MenuContainer>
  );
};

export default MobileMenu;
