import React from "react";
import styled from "styled-components";
import { Button } from ".";
import { responsive } from "bluejay-ui";
const { useMedia, breakpoints } = responsive;

const Navigation = ({ leftButton = {}, rightButton = {}, ...props }) => {
  const defaultMode = useMedia(
    [breakpoints.TABLET],
    ["horizontal"],
    "vertical"
  );

  const { mode = defaultMode } = props;
  const { show: showLeftButton = true } = leftButton;
  const { show: showRightButton = true } = rightButton;

  return (
    <ButtonsContainer {...props} mode={mode}>
      {showLeftButton && <Button color="default" {...leftButton} />}
      {showRightButton && <Button color="primary" {...rightButton} />}
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  button {
    ${({ singleButton }) =>
      singleButton ? " flex-basis:50%; margin-left:auto;" : " flex-basis:65%;"};
  }

  ${({ mode = "horizontal" }) =>
    `
      flex-direction: ${mode === "horizontal" ? "row" : "column"}; 
   
      button:first-child {
        margin-${mode === "horizontal" ? "right" : "bottom"}: 25px;
      }
    `}
`;

export default Navigation;
