const getJSONFormatOfError = (
  name,
  errorCode,
  translationKeyOfMessage,
  colorOfMessage
) => ({
  name,
  errorCode,
  message: {
    color: colorOfMessage,
    translation: translationKeyOfMessage,
  },
});

export const errors = {
  invalidCompanyPayment: getJSONFormatOfError(
    "INVALID_COMPANY_PAYMENT",
    4204,
    "billingData.errors.invalid_company_payment",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidUserPayment: getJSONFormatOfError(
    "INVALID_USER_PAYMENT",
    4205,
    "billingData.errors.invalid_user_payment",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidPromoPayment: getJSONFormatOfError(
    "INVALID_PROMO_PAYMENT",
    4206,
    "billingData.errors.invalid_promo_payment",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidPromoCompany: getJSONFormatOfError(
    "INVALID_PROMO_COMPANY",
    4207,
    "billingData.errors.invalid_promo_company",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidPromoApplication: getJSONFormatOfError(
    "INVALID_PROMO_APPLICATION",
    4208,
    "billingData.errors.invalid_promo_application",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidPromoDate: getJSONFormatOfError(
    "INVALID_PROMO_DATE",
    4209,
    "billingData.errors.invalid_promo_date",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidPromoClientConsumption: getJSONFormatOfError(
    "INVALID_PROMO_CLIENT_CONSUMPTION",
    4210,
    "billingData.errors.invalid_promo_client_consumption",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidVoucherCode: getJSONFormatOfError(
    "INVALID_VOUCHER_CODE",
    4211,
    "billingData.errors.invalid_voucher_code",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  voucherCodeUsed: getJSONFormatOfError(
    "VOUCHER_CODE_USED",
    4212,
    "billingData.errors.voucher_code_used",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  invalidVoucherPaymentMethod: getJSONFormatOfError(
    "INVALID_VOUCHER_PAYMENT_METHOD",
    4213,
    "billingData.errors.invalid_voucher_payment_method",
    "warning"
  ), // not used in web de reservas because it has no support for payments

  tripAlreadyPayed: getJSONFormatOfError(
    "TRIP_ALREADY_PAID",
    4216,
    "trips.errors.trip_already_paid",
    "warning"
  ), // Happens when api request for payments is done.. wont be used here either since we dont make payments in web de reservas

  creditCardExpired: getJSONFormatOfError(
    "CREDIT_CARD_EXPIRED",
    4217,
    "billingData.errors.credit_card_expired",
    "warning"
  ), // Won't be used here since we don't make payments in web de reservas

  insufficientFunds: getJSONFormatOfError(
    "INSUFFICIENT_FUNDS",
    4218,
    "billingData.errors.insufficient_funds",
    "warning"
  ), // Won't be used here since we don't make payments in web de reservas

  paymentIssue: getJSONFormatOfError(
    "PAYMENT_ISSUE",
    4219,
    "billingData.errors.payment_issue",
    "warning"
  ), // Won't be used here since we don't make payments in web de reservas

  noCarCoverage: getJSONFormatOfError(
    "NO_CAR_COVERAGE",
    4202,
    "trips.errors.no_car_coverage",
    "warning"
  ),

  clientBlocked: getJSONFormatOfError(
    "CLIENT_BLOCKED",
    4201,
    "login.errors.client_blocked",
    "warning"
  ),
  invalidBookingDate: getJSONFormatOfError(
    "INVALID_BOOKING_DATE",
    4203,
    "trips.errors.invalid_booking_date",
    "warning"
  ),

  invalidTaxProfileData: getJSONFormatOfError(
    "INVALID_TAX_PROFILE_DATA",
    4214,
    "billingData.errors.invalid_tax_profile_data",
    "warning"
  ),

  invalidSubscriberData: getJSONFormatOfError(
    "INVALID_SUBSCRIBER_DATA",
    4215,
    "subscriberData.errors.invalid_subscriber_data",
    "warning"
  ),

  destinationIsMandatory: getJSONFormatOfError(
    "DESTINATION_IS_MANDATORY",
    4220,
    "trips.errors.destination_is_mandatory",
    "warning"
  ),

  scheduledTripNotAllowed: getJSONFormatOfError(
    "SCHEDULED_TRIP_NOT_ALLOWED",
    4221,
    "trips.errors.scheduled_trip_not_allowed",
    "warning"
  ),
  invalidPickupTooCloseToStand: getJSONFormatOfError(
    "INVALID_PICKUP_TOO_CLOSE_TO_STAND",
    4229,
    "trips.errors.invalid_pickup_too_close_to_stand",
    "warning"
  ),
  noCarMeetRequirements: getJSONFormatOfError(
    "NO_CAR_MEET_REQUIREMENTS",
    4230,
    "trips.errors.no_car_meet_requirements",
    "warning"
  ),

  tripNotFound: getJSONFormatOfError(
    "TRIP_NOT_FOUND",
    4301,
    "trips.errors.trip_not_found",
    "warning"
  ),

  tripNotEditable: getJSONFormatOfError(
    "TRIP_NOT_EDITABLE",
    4302,
    "trips.errors.trip_not_editable",
    "warning"
  ),

  favoritePlaceLimitReached: getJSONFormatOfError(
    "FAVOURITE_PLACE_LIMIT_REACHED",
    4303,
    "favourites.errors.maximum_places_stored",
    "warning"
  ),

  favoritePlaceNameNotUnique: getJSONFormatOfError(
    "FAVOURITE_PLACE_NOT_UNIQUE",
    4304,
    "favourites.errors.place_not_unique",
    "warning"
  ),

  numberOfBagsIsMandatory: getJSONFormatOfError(
    "NUMBER_OF_BAGS_IS_MANDATORY",
    4222,
    "trips.errors.number_of_bags_is_mandatory",
    "warning"
  ), // not found on any composer service

  numberOfPassengersIsMandatory: getJSONFormatOfError(
    "NUMBER_OF_PASSENGERS_IS_MANDATORY",
    4223,
    "trips.errors.number_of_passengers_is_mandatory",
    "warning"
  ), // not found on any composer service

  timezoneConversionError: getJSONFormatOfError(
    "TIME_ZONE_CONVERSION_ERROR",
    4224,
    "general.errors.something_went_wrong",
    "danger"
  ), // not found on any composer service

  simultaneousTripError: getJSONFormatOfError(
    "SIMULTANEOUS_TRIP_ERROR",
    4225,
    "trips.errors.simultaneous_trip_error",
    "warning"
  ), // not found on any composer service

  vehicleNotAvailable: getJSONFormatOfError(
    "VEHICLE_NOT_AVAILABLE",
    4226,
    "trips.errors.vehicle_not_available",
    "warning"
  ), // not found on any composer service

  cancelDenied: getJSONFormatOfError(
    "CANCEL_DENIED",
    4305,
    "trips.errors.cancel_denied",
    "warning"
  ), // not found on any composer service

  clientExists: getJSONFormatOfError(
    "CLIENT_EXISTS",
    4916,
    "register.errors.client_exists",
    "warning"
  ), // not found on any composer service

  userInactive: getJSONFormatOfError(
    "USER_INACTIVE",
    4104,
    "login.errors.user_inactive",
    "danger"
  ),

  userAlreadyExists: getJSONFormatOfError(
    "USER_ALREADY_EXISTS",
    4105,
    "register.errors.client_exists",
    "warning"
  ),

  dispatcherFailedToRespond: getJSONFormatOfError(
    "DISPATCHER_FAILED_TO_RESPOND",
    6001,
    "general.errors.something_went_wrong",
    "danger"
  ), // not found on any composer service

  dispatcherValidationError: getJSONFormatOfError(
    "DISPATCHER_VALIDATION_ERROR",
    6002,
    "general.errors.something_went_wrong",
    "danger"
  ), // not found on any composer service

  subscriberNotFound: getJSONFormatOfError(
    "SUBSCRIBER_NOT_FOUND",
    6003,
    "subscriberData.errors.subscriber_not_found",
    "warning"
  ), // subscriber services not used in web de reservas

  subscriberAccountNotFound: getJSONFormatOfError(
    "SUBSCRIBER_ACCOUNT_NOT_FOUND",
    6004,
    "subscriberData.errors.subscriber_account_not_found",
    "warning"
  ), // subscriber services not used in web de reservas

  subscriberAccountNotActive: getJSONFormatOfError(
    "SUBSCRIBER_ACCOUNT_NOT_ACTIVE",
    6005,
    "subscriberData.errors.subscriber_account_not_active",
    "warning"
  ), // subscriber services not used in web de reservas

  dispatcherRateLimitExceeded: getJSONFormatOfError(
    "DISPATCHER_RATE_LIMIT_EXCEEDED",
    6006,
    "general.errors.something_went_wrong",
    "danger"
  ), // not found on any composer service

  dispatcherNotImplementedException: getJSONFormatOfError(
    "DISPATCHER_NOT_IMPLEMENTED_EXCEPTION",
    6007,
    "general.errors.something_went_wrong",
    "danger"
  ), // not found on any composer service

  tripAlreadyBookedAtDispatcher: getJSONFormatOfError(
    "TRIP_ALREADY_BOOKED_AT_DISPATCHER",
    6008,
    "trips.errors.trip_already_booked_at_dispatcher",
    "warning"
  ), // not found on any composer service

  dispatcherUserBlocked: getJSONFormatOfError(
    "DISPATCHER_USER_BLOCKED",
    6009,
    "trips.errors.dispatcher_user_blocked",
    "warning"
  ), // not found on any composer service

  serverOffline: getJSONFormatOfError(
    "SERVER_OFFLINE",
    5000,
    "server.offline",
    "danger"
  ),

  invalidData: getJSONFormatOfError(
    "INVALID_DATA",
    4000,
    "favourites.errors.maximum_chars_reached",
    "warning"
  ),

  mandatoryFieldMissing: getJSONFormatOfError(
    "MANDATORY_FIELD_MISSING",
    undefined, // not a backend error, status code is irrelevant
    "general.errors.mandatory_field_missing",
    "warning"
  ),
};
