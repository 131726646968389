import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { dateFromTimestamp } from "utils/utils-date";
import { ReactComponent as CloseIcon } from "images/close_orange.svg";
import { ReactComponent as Check } from "images/check_on.svg";
import { ReactComponent as Scheduled } from "images/scheduled.svg";
import { responsive } from "bluejay-ui";
const { mediaQuery } = responsive;

const TripInfoRow = ({ item, onClick }) => {
  const { t } = useTranslation();

  const { id, bookingDate, pickupAddress, status } = item;
  return (
    <Container onClick={() => onClick(id)}>
      <HeaderContainer>
        <InfoLabel>
          <DateContainer>
            {t("book.date")}: {dateFromTimestamp({ date: bookingDate })}
          </DateContainer>
        </InfoLabel>
        <StatusLabelContainer>
          <StatusLabel status={status} />
        </StatusLabelContainer>
      </HeaderContainer>
      <AdressLabel>{pickupAddress.name}</AdressLabel>
      <Separator />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  ${mediaQuery.MOBILE`
  flex-direction: row;  
  `}
`;

const InfoLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #004869;
  order: 1;
  align-self: flex-start;
  margin-bottom: 5px;
  ${mediaQuery.MOBILE`
  order: 0;  
  `}
`;

const StatusLabelContainer = styled.div`
  order: 0;
  margin-bottom: 20px;
  ${mediaQuery.MOBILE`
  margin-bottom: 0px;
  order: 1;  
  `}
`;

const DateContainer = styled.span``;

const AdressLabel = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #004869;
  margin-bottom: 7px;
`;

const Separator = styled.div`
  width: 100%;
  height: 0.5px;
  background: #9b9b9b;
  margin-bottom: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 4px;
    margin-left: 6px;
    height: 15px;
    width: 15px;
  }
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
`;

const StatusLabel = ({ status }) => {
  const { t } = useTranslation();

  const StatusIcon = ({ status }) => {
    switch (status) {
      case 0:
      case 50:
      case 100:
        return <Scheduled />;

      case 200:
      case 300:
      case 400:
      case 600:
      case 955:
        return <Check />;

      case 500:
      case 900:
      case 956:
      case 957:
      case 96:
        return <CloseIcon />;

      default:
        return <></>;
    }
  };

  const labelColor = (status) => {
    switch (status) {
      case 0:
      case 50:
      case 100:
        return { color: "#9B9B9B" };

      case 200:
      case 300:
      case 400:
      case 600:
      case 955:
        return { color: "#0194D9" };

      case 500:
      case 900:
      case 956:
      case 957:
      case 96:
        return { color: "#0194D9" };
      default:
        return {};
    }
  };

  return (
    <LabelContainer>
      <Label style={labelColor(status)}>
        {t(`book.statusCodes.${status}`)}
      </Label>
      <div>
        <StatusIcon status={status} />
      </div>
    </LabelContainer>
  );
};

export default TripInfoRow;
