import React, { useState } from "react";
import request from "utils/request";
import handleErrors from "utils/handleErrors";
import { useTranslation } from "react-i18next";
import { UnAuthForm } from "components/";

const modes = {
  INITIAL: {
    name: "INITIAL",
    message: {},
  },
  RECOVER_SUCCESS: {
    name: "RECOVER_SUCCESS",
    message: {
      color: "primary",
      translation: "recoverPassword.recoverEmailSent",
    },
  },
  USER_NOT_FOUND: {
    name: "USER_NOT_FOUND",
    message: {
      color: "danger",
      translation: "recoverPassword.errors.USER_NOT_FOUND",
    },
    errorCode: 4007,
  },
  SERVER_OFFLINE: {
    name: "SERVER_OFFLINE",
    message: {
      color: "danger",
      translation: "server.offline",
    },
  },
};

const RecoverPassword = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(modes.INITIAL);

  const onSubmit = (credentials) =>
    request(`clients/v1/users/${credentials.email}/reset-password`)
      .then((res) => {
        setMode(modes.RECOVER_SUCCESS);
      })
      .catch((error) => {
        if (error.error && error.error.code === 4007) {
          setMode(modes.USER_NOT_FOUND);
        } else {
          handleErrors({ ...error, modes, setMode });
        }
      });
  const resetMode = () => {
    if (mode !== modes.INITIAL) setMode(modes.INITIAL);
  };

  return (
    <UnAuthForm
      onSubmit={onSubmit}
      resetMode={resetMode}
      title={t("recoverPassword.title")}
      submitText={
        mode !== modes.RECOVER_SUCCESS ? t("recoverPassword.action") : null
      }
      secondaryText={t("general.alreadyHaveAnAccount")}
      secondaryLink={t("general.signin")}
      secondaryTo={"/"}
      fields={
        mode !== modes.RECOVER_SUCCESS
          ? [
              {
                label: t("general.email"),
                placeholder: t("general.email"),
                type: "email",
                name: "email",
                id: "email",
              },
            ]
          : []
      }
      message={mode.message}
    />
  );
};

export default RecoverPassword;
