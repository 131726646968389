import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Modal, Text } from "bluejay-ui";
import {
  tripRequest,
  cancelTripRequest,
  generateTripInvoiceRequest,
} from "utils/requests";
import { mapTakasiTrip } from "utils/trip";
import { appTypes, getAppType } from "utils/app";
import { Label, Navigation, TripSummary, If, Button, Loader } from "ui";
import { TaxProfiles } from "components";
import styled from "styled-components";
import { errors } from "utils/modesErrors";
import handleErrors from "utils/handleErrors";
import { tripStatus as tripStatusEnum } from "takasi-library/src/enums";

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
  TRIP_NOT_FOUND: errors.tripNotFound,
  TRIP_NOT_EDITABLE: errors.tripNotEditable,
  CANCEL_DENIED: errors.cancelDenied,
};

const statusCodes = {
  0: "Pending",
  50: "Scheduled",
  100: "Searching",
};

const Trip = ({ updateRoutes, routes }) => {
  const appType = getAppType();
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [trip, setTrip] = useState({
    pickupAddress: {},
    destinationAddress: {},
    vehiclePreferences: [],
  });

  const [state, setState] = useState({
    name: "",
    isDefault: false,
    mode: modes.INITIAL,
  });

  const [showModal, setShowModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceFeedback, setInvoiceFeedback] = useState({
    show: false,
    succeeded: false,
  });
  const [loading, setLoading] = useState(false);
  const tripsGroup = history.location
    ? history.location.state
      ? history.location.state.trips
      : [params.id]
    : [params.id];

  useEffect(() => {
    getTripData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (trip.pickupAddress.location) {
      updateRoutes({
        origin: mapTakasiTrip(trip).pickupAddress,
        destination: mapTakasiTrip(trip).destinationAddress,
      });
    }
    return () => {
      updateRoutes({
        active: "origin",
        destination: { name: "" },
        disabled: false,
        ...(appType !== appTypes.HOTEL ? { origin: { name: "" } } : {}),
      });
    };
  }, [trip]);

  const updateState = (nextState) =>
    setState((currentState) => ({ ...currentState, ...nextState }));

  const errorCallback = (catchElement) =>
    handleErrors({
      ...catchElement,
      modes,
      setMode: (mode) => updateState({ mode }),
    });

  const isValidUUIDOrHyphenatedString = (id) => {
    const regexUUID =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const regexHyphenatedString = /^[a-z]+(-[a-z]+)*$/i;

    return regexUUID.test(id) || regexHyphenatedString.test(id);
  };

  const fetchTripData = async (tripId) => {
    try {
      const { data } = await tripRequest(tripId);
      if (data) {
        setTrip(data);
      }
    } catch (error) {
      errorCallback(error);
    }
  };

  const getTripData = (tripId) => {
    if (isValidUUIDOrHyphenatedString(tripId)) {
      fetchTripData(tripId);
    } else {
      updateState({ mode: modes.TRIP_NOT_FOUND });
    }
  };

  const tripSummaryResult = useCallback(
    (tripId) => {
      fetchTripData(tripId);
    },
    [trip]
  );

  const generateTripInvoice = (taxId) => {
    setLoading(true);
    generateTripInvoiceRequest({ id: params.id, taxId })
      .then(() => {
        setInvoiceFeedback({ show: true, succeeded: true });
        setLoading(false);
      })
      .catch(() => {
        setInvoiceFeedback({ show: true, succeeded: false });
        setLoading(false);
      });
  };

  const onCloseClick = () => {
    history.push("/");
  };

  const onCancelClick = () => {
    setShowModal(true);
  };

  const onCancelConfirmClick = () => {
    Promise.all(tripsGroup.map((tripGroupId) => cancelTripRequest(tripGroupId)))
      .then(() => getTripData(params.id))
      .then(() => setShowModal(false))
      .catch((catchElement) => errorCallback(catchElement));
  };

  const isErrorMode = state.mode !== modes.INITIAL;

  return (
    <>
      {isErrorMode && (
        <Text color={state.mode.message.color}>
          {t(state.mode.message.translation)}
        </Text>
      )}
      {trip.status && trip.status !== tripStatusEnum.finished ? (
        <Label>{t("book.success")}</Label>
      ) : (
        <></>
      )}
      <If condition={trip.id}>
        <TripSummary
          tripSummaryResult={tripSummaryResult}
          trip={trip && mapTakasiTrip(trip)}
        />
      </If>
      {trip.paid &&
      trip.paymentMethod !== "subscriber" &&
      appType !== appTypes.HOTEL ? (
        <Button
          onClick={() => setShowInvoiceModal(true)}
          data-testid="invoiceRequestButton"
        >
          {t("book.requestBill")}
        </Button>
      ) : (
        <></>
      )}
      <Navigation
        mode="vertical"
        leftButton={{
          children: t("actions.close"),
          onClick: onCloseClick,
        }}
        rightButton={{
          show: Object.keys(statusCodes).map(Number).includes(trip.status),
          children: t("actions.cancel"),
          color: "warning",
          onClick: onCancelClick,
        }}
      />
      {showModal && (
        <Modal Title={Label} title={t("book.wishToCancel")}>
          <Navigation
            leftButton={{
              children: t("general.no"),
              onClick: () => setShowModal(false),
            }}
            rightButton={{
              children: t("general.yes"),
              color: "warning",
              onClick: onCancelConfirmClick,
            }}
          />
        </Modal>
      )}
      {showInvoiceModal && (
        <CustomModal
          Title={Label}
          title={t(
            `book.${
              invoiceFeedback.show
                ? invoiceFeedback.succeeded
                  ? "invoiceSuccessMessage"
                  : "invoiceFailMessage"
                : "chooseTaxProfile"
            }`
          )}
          marginBottom={invoiceFeedback.show ? "30px" : "0px"}
        >
          {!invoiceFeedback.show && (
            <Loader loading={loading}>
              <TaxProfiles
                onRowClick={({ e, taxId }) => {
                  e.preventDefault();
                  generateTripInvoice(taxId);
                }}
              />
            </Loader>
          )}
          <Navigation
            mode="vertical"
            leftButton={{
              "data-testid": "closeButton",
              children: t("actions.close"),
              onClick: () => {
                setShowInvoiceModal(false);
                if (invoiceFeedback.show)
                  setInvoiceFeedback({ show: false, succeeded: false });
              },
            }}
            rightButton={{
              show: false,
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

const CustomModal = styled(Modal)`
  p {
    margin-bottom: ${({ marginBottom }) => marginBottom} !important;
  }
`;

export default Trip;
