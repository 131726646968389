import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import { ReactComponent as GPS } from "images/gps_fixed-24px.svg";
import OriginMarker from "./../../images/blue_marker.png";
import DestinationMarker from "./../../images/orange_marker.png";
import { appTypes, getAppType } from "utils/app";

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MyLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 120px;
  right: 10px;
  height: 40px;
  width: 40px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;

  svg {
    height: 25px;
    width: auto;
    fill: #5b5b5b;

    &:hover {
      fill: #2d2d2d;
    }
  }
`;

const CustomGoogleMap = ({
  mapConfig,
  routes,
  onLoad = () => {},
  onMapPan = () => {},
  onMyLocationClick = () => {},
}) => {

  const appType = getAppType();

  return (
    <>
      <GoogleMapReact
        center={mapConfig.center}
        zoom={mapConfig.zoom}
        bootstrapURLKeys={{
          key,
          libraries: ["places"],
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          onLoad({ map, maps });
        }}
        yesIWantToUseGoogleMapApiInternals
        options={{ fullscreenControl: false }}
        onChange={(props) => {
          onMapPan(props);
        }}
      >
        {routes.origin && routes.origin.location && (
          <LocationMarker
            lat={routes.origin.location.latitude}
            lng={routes.origin.location.longitude}
            src={OriginMarker}
          />
        )}
        {routes.destination && routes.destination.location && (
          <LocationMarker
            lat={routes.destination.location.latitude}
            lng={routes.destination.location.longitude}
            src={DestinationMarker}
          />
        )}
      </GoogleMapReact>

      {appType === appTypes.END_USER ? (
        <MyLocation
          onClick={onMyLocationClick}
          data-testid="my-location-button"
        >
          <GPS />
        </MyLocation>
      ) : (
        <></>
      )}
    </>
  );
};

const LocationMarker = ({ src }) => (
  <div>
    <img
      alt="marker"
      src={src}
      style={{ transform: "translateZ(0) translate(-50%, -50%)" }}
    />
  </div>
);

export default CustomGoogleMap;
