import React, { useState } from "react";
import styled from "styled-components";
import UnAuthApp from "./UnAuthApp";
import AuthApp from "./AuthApp";

const Container = styled.div`
  height: 100%;
`;

const initState = (key, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return defaultValue;
  }
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(initState("isLoggedIn", false));
  const [user, setUser] = useState(initState("user", {}));

  const login = ({ token, refreshToken, user }) => {
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(user));

    setUser(user);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    setUser({});
    setIsLoggedIn(false);
  };

  return (
    <Container>
      {isLoggedIn ? (
        <AuthApp user={user} logout={logout} />
      ) : (
        <UnAuthApp login={login} />
      )}
    </Container>
  );
};

export default App;
