import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Input, Text, Button } from "bluejay-ui";
import { useTranslation } from "react-i18next";
import {
  getFavouriteAddresses,
  removeAddressFromFavourites,
} from "utils/requests";
import { useDebounce } from "utils/hooks";
import { searchModes, getBestRoutesFirst } from "utils/map";
import { appTypes, getAppType } from "utils/app";
import { DirectionsTabs, MobileMenu, ResponsiveContainer } from "ui";
import { ReactComponent as AddressDots } from "images/address-dots.svg";
import { ReactComponent as FavouriteOnIcon } from "images/favourite-on.svg";
import { ReactComponent as FavouriteOffIcon } from "images/favourite-off.svg";
import { BookingContext } from "contexts";

const AddressFinder = ({
  isMobile,
  searching,
  updateSearching,
  routes,
  onRouteChange,
  saveOriginAddressAsFavourite,
  updateRoutes,
  googleServices,
  mapConfig,
  updateMapConfig,
  onRouteClick,
  userPosition,
}) => {
  const debouncedSearch = useDebounce(routes[routes.active].name, 500);
  const { booking } = useContext(BookingContext);
  const [suggestions, setSuggestions] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const companyPOIs = booking.company.pois.map((poi) => {
    const splittedAddress = poi.address.split(",");
    return {
      ...poi,
      address: {
        ...(poi.address?.location
          ? { ...poi.address }
          : {
              streetName: splittedAddress[0],
              buildingNumber: splittedAddress[1],
              cityName: splittedAddress[2],
              countryName: splittedAddress[3],
              location: {
                longitude: poi.geometry.coordinates[0],
                latitude: poi.geometry.coordinates[1],
              },
            }),
      },
      isPoi: true,
    };
  });

  const appType = getAppType();

  useEffect(() => {
    getFavouriteAddresses().then(({ data }) => {
      setFavourites(data);
    });
  }, []);

  useEffect(() => {
    if (
      routes.origin.isFavourite &&
      routes.origin.favouriteProps &&
      !favourites.some(({ id }) => id === routes.origin.id)
    ) {
      setFavourites([...favourites, routes.origin.favouriteProps]);
    }
  }, [routes]);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser && appType === appTypes.HOTEL) {
      if (loggedInUser.resources.length > 0) {
        const defaultOriginAddress = loggedInUser.resources[0].relationData;

        const center = {
          lat: defaultOriginAddress.location.latitude,
          lng: defaultOriginAddress.location.longitude,
        };
        updateMapConfig({ center });
      }
    }
  }, [googleServices, favourites]);

  useEffect(() => {
    if (searching.isSearching) {
      if (debouncedSearch && debouncedSearch.length >= 3) {
        googleServices.autocomplete?.getPlacePredictions(
          {
            input: debouncedSearch,
            componentRestrictions: { country: ["es", "ad"] },
            ...(userPosition
              ? {
                  locationBias: {
                    center: {
                      lat: userPosition.coords.latitude,
                      lng: userPosition.coords.longitude,
                    },
                    radius: 100000,
                  },
                }
              : {}),
          },
          (results, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              console.error("Error with Places API:", status);
            }
            setSuggestions(getBestRoutesFirst(results));
          }
        );
      }
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearch, searching.isSearching]);

  const removeFavouriteAddress = (idToRemove) => {
    removeAddressFromFavourites(idToRemove).then(() => {
      if (routes.origin.isFavourite) {
        updateRoutes({ origin: { ...routes.origin, isFavourite: false } });
      }
      const filteredFavourites = favourites.filter(
        ({ id }) => id !== idToRemove
      );
      if (filteredFavourites < favourites) {
        setFavourites(filteredFavourites);
      }
    });
  };
  return (
    <ControlsContainer>
      {isMobile && !searching.isSearching && <MobileMenu />}
      <RouteContainer>
        <ResponsiveContainer>
          {isMobile && searching.isSearching && appType !== appTypes.HOTEL && (
            <DirectionsTabs
              onTabClick={(mode) => updateSearching({ mode })}
              style={{ padding: 0 }}
            />
          )}
          <RouteInputs
            routes={routes}
            onRouteChange={({ target: { name, value } }) =>
              onRouteChange({ key: name, name: value })
            }
            onIconClick={({ id }) =>
              id ? removeFavouriteAddress(id) : saveOriginAddressAsFavourite()
            }
            onFocus={(e) => {
              updateRoutes({ active: e.target.name });
              updateSearching({
                isSearching: true,
              });
            }}
          />
        </ResponsiveContainer>
      </RouteContainer>
      <ResponsiveContainer>
        {searching.isSearching && (
          <AddressesList
            addresses={
              searching.mode === searchModes.SUGGEST
                ? suggestions
                : [...favourites, ...companyPOIs]
            }
            onClick={onRouteClick}
            removeAddress={removeFavouriteAddress}
          />
        )}
      </ResponsiveContainer>
    </ControlsContainer>
  );
};

const RouteInputs = ({
  routes,
  onRouteChange = () => {},
  onIconClick = () => {},
  onFocus = () => {},
}) => {
  const { t } = useTranslation();
  const hasZerosInBuildingNumberOfOriginName =
    routes.origin?.name?.includes(" 0,");
  const hasZerosInBuildingNumberOfDestinationName =
    routes.destination?.name?.includes(" 0,");
  const originValue = hasZerosInBuildingNumberOfOriginName
    ? routes.origin?.name?.replace(" 0,", "")
    : routes.origin?.name;

  const destinationValue = hasZerosInBuildingNumberOfDestinationName
    ? routes.destination?.name?.replace(" 0,", "")
    : routes.destination?.name;

  const appType = getAppType();

  return (
    <Card>
      <AddressDots />
      <InputsContainer data-testid="address-finder">
        <AddressInput
          style={{ borderBottom: "1px solid #a0a0a0" }}
          value={originValue}
          name="origin"
          placeholder={t("map.origin")}
          autoComplete="off"
          onChange={onRouteChange}
          onFocus={onFocus}
          {...(routes.origin.location && appType !== appTypes.HOTEL
            ? {
                icon: routes.origin.isFavourite
                  ? FavouriteOnIcon
                  : FavouriteOffIcon,
                iconPosition: "end",
                onIconClick: () =>
                  onIconClick({
                    id: routes.origin.isFavourite ? routes.origin.id : null,
                  }),
              }
            : {})}
          data-testid="origin-address"
          disabled={appType === appTypes.HOTEL || routes.disabled}
        />
        <AddressInput
          disabled={routes.disabled}
          value={routes.destination ? destinationValue : ""}
          name="destination"
          placeholder={t("map.destination")}
          autoComplete="off"
          onChange={onRouteChange}
          onFocus={onFocus}
          data-testid="destination-address"
        />
      </InputsContainer>
    </Card>
  );
};

const AddressesList = ({
  addresses = [],
  onClick,
  removeAddress = () => {},
}) => {
  return (
    <AddressesContainer data-testid="addresses-predictions">
      {addresses &&
        addresses.map((address, key) => (
          <div
            key={key}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <Text
              style={{
                cursor: "pointer",
                marginTop: 0,
                marginBottom: 0,
              }}
              onClick={() => onClick(address)}
            >
              {address.description || address.name}
            </Text>
            {address.created_at && !address.isPoi ? (
              <Button
                data-testid={`${address.description || address.name}-favourite`}
                color={"transparent"}
                icon={FavouriteOnIcon}
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeAddress(address.id);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
    </AddressesContainer>
  );
};

const ControlsContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const RouteContainer = styled.div`
  padding-top: 25px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const AddressesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 5;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const InputsContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  padding-right: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    margin: 0;
  }
`;

const AddressInput = styled(Input)`
  border-radius: 0px;
  border: none;
  margin-bottom: 0;
  padding-left: 2px;
  padding-right: 0;
  width: 100%;
`;

export default AddressFinder;
