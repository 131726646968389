import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RadioButton, Modal, Checkbox, Button } from "bluejay-ui";
import { Label, Input, Navigation, Loader } from "ui";
import { appTypes, getAppType } from "utils/app";
import { ReactComponent as Minus } from "images/minus.svg";
import { ReactComponent as Plus } from "images/plus.svg";
import { ZoneRequiredPreferencesModal } from "components";

const distanceStyles = {
  borderLeft: "solid 1px #ccc",
  borderRight: "solid 1px #ccc",
};

const MAX_TAXIS_COUNT = 10;
const MIN_TAXIS_COUNT = 1;

const Book = ({
  toogleCompanyPreference,
  toogleTripSpecification,
  toogleEstimationincludesTolls,
  trip,
  booking,
  updateBooking,
  routes,
  updateRoutes,
}) => {
  const appType = getAppType();
  const { t } = useTranslation();
  const history = useHistory();
  const [streetNameModal, setStreetNameModal] = useState({
    open: false,
    originOrDestination: "origin",
  });
  const [missingStreetName, setMissingStreetName] = useState();
  const [showZonePreferencesModal, setShowZonePreferencesModal] =
    useState(false);

  const includesTolls = booking.company.includesTolls;
  const tollsAvailable = routes.estimationWithTolls?.includesTolls;
  const canShowTripEstimation =
    routes.origin?.location && routes.destination?.location;
  const companyAllowsMaxAgreedPrice =
    booking.company?.companyAllowsMaxAgreedPrice;
  const companyAllowsFixedAgreedPrice =
    booking.company?.companyAllowsFixedAgreedPrice;
  const availableSpecifications = () => {
    if (companyAllowsMaxAgreedPrice && companyAllowsFixedAgreedPrice) {
      return booking.company?.specifications;
    } else if (companyAllowsMaxAgreedPrice) {
      return booking.company?.specifications.filter(
        ({ value }) => value === "taximeter" || value === "maxPrice"
      );
    } else if (companyAllowsFixedAgreedPrice) {
      return booking.company?.specifications.filter(
        ({ value }) => value === "taximeter" || value === "fixedPrice"
      );
    } else {
      return booking.company?.specifications.filter(
        ({ value }) => value === "taximeter"
      );
    }
  };
  const checkedSpecification = availableSpecifications()?.find(
    ({ checked }) => checked
  );
  const fixedPriceOptionSelected = checkedSpecification?.value === "fixedPrice";
  const maxPriceOptionSelected = checkedSpecification?.value === "maxPrice";
  const canScheduleTrip = booking.company?.canScheduleTrip
    ? booking.company.canScheduleTrip
    : false;
  const isScheduleButtonDisabled =
    !canScheduleTrip ||
    !trip.isValid ||
    (!routes.estimationFinished && routes.destination?.location);
  const isBookNowButtonDisabled =
    !trip.isValid ||
    (!routes.estimationFinished && routes.destination?.location);
  const showZoneRequiredPreferencesModal =
    booking.requiredEstimationPreferences &&
    !booking.requiredEstimationPreferences.every(
      ({ value }) => value && value !== ""
    ) &&
    routes.destination?.location;

  return (
    <>
      {appType === appTypes.HOTEL && (
        <FormGroup style={{ maxWidth: "30%" }}>
          <Label>{t("book.taxisCount")}</Label>
          <TaxisCount>
            <Input
              type="number"
              name="taxisCount"
              max={MAX_TAXIS_COUNT}
              min={MIN_TAXIS_COUNT}
              value={booking.taxisCount}
              onChange={({ target: { value } }) =>
                updateBooking({ taxisCount: value })
              }
              readOnly={true}
            />
            <ButtonsContainer>
              <Counter
                data-testid="minus-taxi-count"
                icon={Minus}
                color="secondary"
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                disabled={booking.taxisCount === MIN_TAXIS_COUNT}
                onClick={() => {
                  if (booking.taxisCount !== MIN_TAXIS_COUNT) {
                    updateBooking({
                      taxisCount: booking.taxisCount - 1,
                    });
                  }
                }}
              />
              <Counter
                data-testid="plus-taxi-count"
                icon={Plus}
                color="secondary"
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                disabled={booking.taxisCount === MAX_TAXIS_COUNT}
                onClick={() => {
                  if (booking.taxisCount !== MAX_TAXIS_COUNT) {
                    updateBooking({
                      taxisCount: booking.taxisCount + 1,
                    });
                  }
                }}
              />
            </ButtonsContainer>
          </TaxisCount>
        </FormGroup>
      )}
      {(showZoneRequiredPreferencesModal || showZonePreferencesModal) && (
        <ZoneRequiredPreferencesModal
          requiredInputs={booking.requiredEstimationPreferences}
          onConfirmClick={(filledInputs) => {
            updateBooking({ requiredEstimationPreferences: filledInputs });
            setShowZonePreferencesModal(false);
          }}
        />
      )}
      {canShowTripEstimation && (
        <Loader
          containerStyle={{ padding: 0 }}
          loading={!routes.estimationFinished}
        >
          {routes.estimation && (
            <FormGroup>
              <Label>{t("book.specifications")}</Label>
              <EstimationContainer>
                <RadiosAndNumberOfPassengersAndBags>
                  <RadioButtonContainer
                    style={{ width: "20vw", minWidth: 220 }}
                  >
                    {availableSpecifications()?.map(
                      ({ value, checked = false }, key) => (
                        <RadioButton
                          style={{
                            marginBottom: 5,
                            marginTop: 10,
                            flex: 1,
                          }}
                          key={key}
                          label={t(`book.${value}`)}
                          name={t(`book.${value}`)}
                          onChange={() => toogleTripSpecification(key)}
                          checked={checked}
                          data-testid={`radio-${value}`}
                        />
                      )
                    )}
                  </RadioButtonContainer>
                  {booking.requiredEstimationPreferences?.length > 0 && (
                    <NumberOfPassengersAndBagsContainer>
                      <NumberOfPassengersAndBags>
                        {booking.requiredEstimationPreferences.map(
                          ({ name, value }) => (
                            <SecondaryTitle key={name}>
                              {t(`zonePreferences.short_${name}`)}
                              <span
                                style={{ color: "#000" }}
                                data-testid={`${name}-value`}
                              >
                                {value}
                              </span>
                            </SecondaryTitle>
                          )
                        )}
                      </NumberOfPassengersAndBags>
                      <EditButtonContainer>
                        <EditButton
                          data-testid={"showZonePreferencesModalButton"}
                          children={t("actions.edit")}
                          onClick={() => {
                            setShowZonePreferencesModal(true);
                          }}
                        />
                      </EditButtonContainer>
                    </NumberOfPassengersAndBagsContainer>
                  )}
                </RadiosAndNumberOfPassengersAndBags>
                {(fixedPriceOptionSelected || maxPriceOptionSelected) &&
                  tollsAvailable && (
                    <CheckboxContainer style={{ flex: 2 }}>
                      <Checkbox
                        style={{ flex: 1, marginBottom: 5 }}
                        label={t("book.includesTolls")}
                        name={"includesTolls"}
                        onChange={() => toogleEstimationincludesTolls()}
                        checked={includesTolls}
                      />
                    </CheckboxContainer>
                  )}
                {((!companyAllowsMaxAgreedPrice &&
                  !companyAllowsFixedAgreedPrice) ||
                  maxPriceOptionSelected ||
                  fixedPriceOptionSelected) && (
                  <EstimatedPriceContainer>
                    {Object.keys(
                      includesTolls
                        ? routes.estimationWithTolls
                        : routes.estimation
                    )
                      .filter((key) =>
                        ["price", "distance", "eta"].includes(key)
                      )
                      .map((key, index) => {
                        const isPrice = key === "price";
                        const isDistance = key === "distance";
                        const activeEstimation = includesTolls
                          ? routes.estimationWithTolls
                          : routes.estimation;
                        return (
                          <EstimationCell
                            key={index}
                            style={isDistance ? distanceStyles : {}}
                          >
                            <SecondaryTitle>{t(`book.${key}`)}</SecondaryTitle>
                            <EstimationValue
                              data-testid={`estimated-${key}-value`}
                            >
                              {`${activeEstimation[key]}${
                                isPrice
                                  ? activeEstimation.currencySymbol || ""
                                  : isDistance
                                  ? ` ${activeEstimation.distanceUnit || ""}`
                                  : ""
                              }`}
                            </EstimationValue>
                          </EstimationCell>
                        );
                      })}
                  </EstimatedPriceContainer>
                )}
              </EstimationContainer>
            </FormGroup>
          )}
        </Loader>
      )}
      <FormGroup>
        <Label>{t("book.requirements")}</Label>
        <CheckboxContainer>
          {booking.company &&
            booking.company.preferences.map(
              ({ name, checked = false }, key) => (
                <Checkbox
                  style={{ marginBottom: 5 }}
                  key={key}
                  label={name}
                  name={name}
                  onChange={() => toogleCompanyPreference(key)}
                  checked={checked}
                />
              )
            )}
        </CheckboxContainer>
      </FormGroup>
      <FormGroup>
        <Label>{t("book.notes")}</Label>
        <Input
          as="textarea"
          value={booking.remarks}
          onChange={(e) => {
            e.persist();
            updateBooking({ remarks: e.target.value });
          }}
          style={{ padding: 10, resize: "none" }}
          rows={10}
        />
      </FormGroup>
      <Navigation
        leftButton={{
          disabled: isScheduleButtonDisabled,
          children: t("book.inFuture"),
          onClick: () => {
            const { informationLeft, originOrDestination } =
              validateTripInformation(trip);

            if (informationLeft) {
              setStreetNameModal({
                open: true,
                originOrDestination,
              });
            } else {
              updateBooking({ bookingType: 1 });
              history.push("/schedule");
            }
          },
        }}
        rightButton={{
          disabled: isBookNowButtonDisabled,
          children: t("book.now"),
          onClick: () => {
            const { informationLeft, originOrDestination } =
              validateTripInformation(trip);

            if (informationLeft) {
              setStreetNameModal({
                open: true,
                originOrDestination,
              });
            } else {
              history.push("/summary");
            }
          },
          "data-testid": "book.now",
        }}
      />
      {streetNameModal.open && (
        <>
          <Modal
            title={t(
              `book.${streetNameModal.originOrDestination}StreetNameMissing`
            )}
          >
            <Input
              style={{ marginBottom: 10 }}
              label={t("general.name")}
              name="name"
              id="name"
              onChange={({ target: { value } }) => setMissingStreetName(value)}
              autoComplete="off"
            />
            <Navigation
              leftButton={{
                children: t("actions.cancel"),
                onClick: () => {
                  setStreetNameModal({ open: false });
                },
              }}
              rightButton={{
                children: t("actions.save"),
                color: "primary",
                onClick: () => {
                  if (missingStreetName && missingStreetName !== "") {
                    updateRoutes({
                      [streetNameModal.originOrDestination]: {
                        ...routes[streetNameModal.originOrDestination],
                        streetName: missingStreetName,
                      },
                    });
                    setStreetNameModal({ open: false });
                  }
                },
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};
const validateTripInformation = (trip) => {
  if (trip.pickupAddress.streetName === "0") {
    return { informationLeft: true, originOrDestination: "origin" };
  } else if (trip.destinationAddress?.streetName === "0") {
    return { informationLeft: true, originOrDestination: "destination" };
  } else {
    return { informationLeft: false };
  }
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const EstimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TaxisCount = styled.div`
  display: flex;

  input {
    margin-bottom: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 20px;
`;

const Counter = styled(Button)`
  width: 40px;
  height: 40px;

  svg {
    width: 15px;
    height: 15px;
  }

  :disabled {
    path {
      fill: ${({ theme }) => theme.secondary};
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    flex-basis: 33%;
  }
`;

const RadiosAndNumberOfPassengersAndBags = styled.div`
  min-height: 65px;
  div {
    flex-basis: 24%;
  }
`;

const NumberOfPassengersAndBagsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NumberOfPassengersAndBags = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;
const EditButtonContainer = styled.div`
  flex: 1;
`;
const EditButton = styled(Button)`
  font-size: 10px;
`;

const RadioButtonContainer = styled(CheckboxContainer)`
  min-height: 65px;
  div {
    flex-basis: 24%;
  }
`;

const EstimatedPriceContainer = styled.div`
  flex-basis: 50% !important;
  border: solid 1px #ccc;
  border-radius: 5px;
  display: flex;
`;

const EstimationCell = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const SecondaryTitle = styled.span`
  color: ${({ theme }) => theme.primary};
  flex: 1;
`;
const EstimationValue = styled.span`
  flex: 2;
  margin-top: 5px;
  font-weight: bold;
`;

export default Book;
