import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Navigation, Button } from "ui";
import { TaxProfiles } from "components";

const BillingData = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Container>
        <Button
          color="primary"
          style={{
            fontWeight: "bold",
            textTransform: "none",
            width: 80,
            alignSelf: "flex-end",
            marginBottom: 20,
          }}
          onClick={() => history.push("/billing-data/new")}
        >
          {t("actions.add")}
        </Button>
        <TaxProfiles isDeletable={true} />
      </Container>
      <Navigation
        mode="vertical"
        leftButton={{
          show: false,
        }}
        rightButton={{
          children: t("actions.back"),
          color: "warning",
          onClick: () => {
            history.push("/profile");
          },
        }}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export default BillingData;
