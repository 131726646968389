import styled from "styled-components";
import { responsive } from "bluejay-ui";
const { mediaQuery } = responsive;

export const StyledInput = styled.input`
  background-color: rgba(160, 160, 160, 0.12);
  margin-bottom: 35px;
  border-radius: 0px;
  border: none;
  width: auto;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 2.2rem;
  border-bottom: 2px solid #a0a0a0;

  ::placeholder {
    color: #c4c4c4;
    text-transform: lowercase;
  }
`;

export const StyledTimer = styled.input`
  background-color: rgba(160, 160, 160, 0.12);
  margin-bottom: 35px;
  border-radius: 0px;
  border: none;
  width: auto;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 2.2rem;
  border-bottom: 2px solid #a0a0a0;

  ::placeholder {
    color: #c4c4c4;
    text-transform: lowercase;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mediaQuery.TABLET`
    flex-direction: row;
    padding-bottom: 200px;
    justify-content: space-around;
  `}
  label {
    display: inline-block;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
  }
  > div {
    display: flex;
    flex-basis: 48%;
    flex-direction: column;
  }
`;
