import React, { useEffect, useState } from "react";
import { myTripsRequest } from "utils/requests";
import { status, mapTakasiTrip, getWellNamedAddresses } from "utils/trip";
import { useHistory } from "react-router-dom";
import { Navigation } from "ui";
import { useTranslation } from "react-i18next";
import TripInfoRow from "ui/TripInfoRow";
import HistoryStatusSelector from "ui/HistoryStatusSelector";
import Loader from "ui/Loader";
import styled from "styled-components";

const MyTrips = () => {
  const [currentStatus, setCurrentStatus] = useState(status.INPROGRESS);
  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    myTripsRequest(currentStatus.url).then(({ data }) => {
      setIsLoading(false);
      setTrips({
        [currentStatus.name]: data.result.map((trip) => mapTakasiTrip(trip)),
      });
    });
  }, [currentStatus]);

  return (
    <>
      <HistoryStatusSelector
        currentStatus={currentStatus}
        onChangeStatus={setCurrentStatus}
      />
      <Loader loading={isLoading}>
        <ItemsList
          {...{
            isLoading,
            ...{
              items: getWellNamedTrips(trips[currentStatus.name]),
              Row: TripInfoRow,
              clickRow: (id) => history.push("/trips/" + id),
            },
          }}
        />
      </Loader>
      <Navigation
        rightButton={{ show: false }}
        leftButton={{
          children: t("actions.back"),
          onClick: () => {
            history.push("/");
          },
        }}
      />
    </>
  );
};

const Container = styled.div`
  overflow-y: auto;
  margin-top: 50px;
  min-height: 300px;
`;

const EmptyView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const ItemsList = ({ items, Row, clickRow, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {!isLoading &&
        (items && items.length ? (
          items.map((item, key) => (
            <Row key={key} item={item} onClick={clickRow} />
          ))
        ) : (
          <EmptyView>{t("general.emptyData")}</EmptyView>
        ))}
    </Container>
  );
};

const getWellNamedTrips = (trips) => {
  return trips && Array.isArray(trips)
    ? trips.map((trip) => {
        const { pickupName, destinationName } = getWellNamedAddresses(trip);
        return {
          ...trip,
          pickupAddress: {
            ...trip.pickupAddress,
            name: pickupName,
          },
          ...(trip.destinationAddress
            ? {
                destinationAddress: {
                  ...trip.destinationAddress,
                  name: destinationName,
                },
              }
            : {}),
        };
      })
    : trips;
};

export default MyTrips;
