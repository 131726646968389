import React, { useState, createContext } from "react";
const BookingContext = createContext();

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
};

const initBooking = () => ({
  company: {
    preferences: [],
    specifications: [
      { value: "taximeter", checked: false },
      { value: "fixedPrice", checked: false },
      { value: "maxPrice", checked: false },
    ],
    pois: [],
    includesTolls: false,
  },
  requiredEstimationPreferences: [],
  remarks: "",
  taxisCount: 1,
});

const BookingProvider = ({ children }) => {
  const [booking, setBooking] = useState(initBooking());
  const [bookingScreensErrorState, setBookingScreensErrorState] = useState({
    mode: modes.INITIAL,
  });

  const updateBookingScreensErrorState = (nextState) =>
    setBookingScreensErrorState((currentState) => ({
      ...currentState,
      ...nextState,
    }));

  const updateBooking = (nextBooking) => {
    setBooking((booking) => ({ ...booking, ...nextBooking }));
  };

  const resetBooking = () => setBooking(initBooking());

  const toogleCompanyPreference = (index) =>
    updateBooking({
      company: {
        ...booking.company,
        preferences: booking.company.preferences.map(
          ({ checked = false, ...preference }, idx) => ({
            ...preference,
            checked: idx === index ? !checked : checked,
          })
        ),
      },
    });

  const toogleTripSpecification = (index) =>
    updateBooking({
      company: {
        ...booking.company,
        specifications: booking.company.specifications.map(
          ({ checked = false, ...specification }, idx) => ({
            ...specification,
            checked: idx === index,
          })
        ),
      },
    });

  const toogleTripSpecificationAndUnallowPriceTypeSelected = (
    index,
    selectedPriceTypeOption
  ) =>
    updateBooking({
      company: {
        ...booking.company,
        companyAllowsMaxAgreedPrice:
          selectedPriceTypeOption === "maxPrice"
            ? false
            : booking.company.companyAllowsMaxAgreedPrice,
        companyAllowsFixedAgreedPrice:
          selectedPriceTypeOption === "fixedPrice"
            ? false
            : booking.company.companyAllowsMaxAgreedPrice,
        specifications: booking.company.specifications.map(
          ({ checked = false, ...specification }, idx) => ({
            ...specification,
            checked: idx === index,
          })
        ),
      },
    });

  const toogleEstimationincludesTolls = () =>
    updateBooking({
      company: {
        ...booking.company,
        includesTolls: !booking.company.includesTolls,
      },
    });

  return (
    <BookingContext.Provider
      value={{
        booking,
        bookingScreensErrorState,
        updateBookingScreensErrorState,
        updateBooking,
        toogleCompanyPreference,
        toogleTripSpecification,
        toogleTripSpecificationAndUnallowPriceTypeSelected,
        toogleEstimationincludesTolls,
        resetBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContext;
export { BookingContext, BookingProvider };
