import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TabsSelector from "./TabsSelector";
import { ReactComponent as MyTrips } from "images/my-trips.svg";
import { ReactComponent as User } from "images/user.svg";

const CustomTabsSelector = styled(TabsSelector)`
  position: absolute;
  top: 40px;
  right: 60px;
`;

const Menu = ({ user }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const userFullName = `${user.firstName} ${user.lastName || ""}`;

  return (
    <CustomTabsSelector
      tabs={[
        {
          color: "secondary",
          icon: MyTrips,
          onClick: () => history.push("/trips"),
          children: t("general.myTrips"),
        },
        {
          color: "secondary",
          icon: User,
          onClick: () => history.push("/profile"),
          children: user ? userFullName : "",
        },
      ]}
    />
  );
};

export default Menu;
