import { tripSource } from "takasi-library/src/enums";
import { appTypes, getAppType } from "utils/app";

const googleToTakasiPropsMapping = {
  buildingNumber: "street_number",
  streetName: ["route"],
  countryName: "country",
  cityName: ["locality", "administrative_area_level_2"],
};

export const mapBookingToTrip = ({ booking, routes, clientIpAddress }) => {
  const selectedSpecification = booking.company.specifications.find(
    ({ checked }) => checked
  );
  const selectedEstimation = booking.company.includesTolls
    ? routes.estimationWithTolls
    : routes.estimation;
  const price = selectedEstimation?.price;
  const routeId = selectedEstimation?.estimatedRouteId;
  const numberOfBags = booking.requiredEstimationPreferences?.find(
    ({ name }) => name === "isBagsMandatory"
  );
  const numberOfPassengers = booking.requiredEstimationPreferences?.find(
    ({ name }) => name === "isPassengersMandatory"
  );
  return {
    clientIp: clientIpAddress,
    isValid: isAValidTrip({ booking, routes }),
    bookingType: booking.bookingType || 0,
    ...(booking.bookingType === 1 ? { bookingDate: booking.bookingDate } : {}),
    paymentMethod: "cash",
    tripSource:
      getAppType() === appTypes.HOTEL ? tripSource.hotel : tripSource.web,
    pickupAddress: routes.origin,
    ...(routes.destination?.location
      ? { destinationAddress: routes.destination }
      : {}),
    ...(selectedSpecification &&
    selectedSpecification.value &&
    selectedSpecification.value !== "taximeter" &&
    routes.destination &&
    routes.destination.location &&
    routeId &&
    price
      ? {
          agreedPrice: price,
          agreedPriceType: selectedSpecification.value,
          routeId,
        }
      : {}),
    remarks: booking.remarks,
    taxisCount: booking.taxisCount,
    ...(booking.company
      ? {
          companyId: booking.company.id,
          vehiclePreferences: booking.company.preferences.filter(
            ({ checked = false }) => checked
          ),
        }
      : {}),
    ...(numberOfBags
      ? {
          numberOfBags: Number(numberOfBags.value),
        }
      : { numberOfBags: undefined }),
    ...(numberOfPassengers
      ? {
          numberOfPassengers: Number(numberOfPassengers.value),
        }
      : {
          numberOfPassengers: undefined,
        }),
  };
};

export const isAValidTrip = ({ booking, routes }) =>
  routes.origin?.name !== "" &&
  typeof routes.origin?.location !== "undefined" &&
  (booking.company.isDestinationMandatory || routes.destination?.name !== ""
    ? typeof routes.destination?.location !== "undefined"
    : true) &&
  booking.company &&
  !!booking.company.id;

export const mapGooglePlaceToTakasiAddress = (place) => ({
  id: place.id,
  place_id: place.place_id,
  name: place.formatted_address,
  location: {
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  },
  ...Object.keys(googleToTakasiPropsMapping).reduce(
    (reducer, key) => ({
      ...reducer,
      [key]: getAddressInfo({
        address: place.address_components,
        type: googleToTakasiPropsMapping[key],
      }),
    }),
    {}
  ),
});

const getAddressInfo = ({ address, type }) => {
  const foundObject = address.find((addressComponent) =>
    Array.isArray(type)
      ? addressComponent.types.find((t) => type.includes(t))
      : addressComponent.types.includes(type)
  );

  return foundObject ? foundObject.long_name : "0";
};

export const isActiveBooking = (booking, routes) =>
  [
    booking.taxisCount > 1,
    booking.company &&
      booking.company.preferences.some(({ checked = false }) => checked),
    booking.remarks.length,
    routes.destination ? routes.destination.location : {},
  ].some((item) => item);

export const companyAllowsMaxAgreedPrice = (data) =>
  data.company?.paymentMethods?.find(
    ({ name, agreedPriceTypes }) =>
      (name === "cash" || name === "creditCardInTaxi") &&
      agreedPriceTypes.find(({ name }) => name === "maxPrice")
  );

export const companyAllowsFixedAgreedPrice = (data) =>
  data.company?.paymentMethods?.find(
    ({ name, agreedPriceTypes }) =>
      (name === "cash" || name === "creditCardInTaxi") &&
      agreedPriceTypes.find(({ name }) => name === "fixedPrice")
  );

export const getClientIpAddress = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_IPIFY_URL);
    if (!response.ok) {
      throw new Error("No se pudo obtener la dirección IP");
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error al obtener la IP:", error);
    return null;
  }
};
