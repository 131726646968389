import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { responsive, Text } from "bluejay-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Navigation, Input, Button } from "ui";
import { getTaxProfileData, createTaxProfileRequest } from "utils/requests";
import { errors } from "utils/modesErrors";
import { isEmptyObject } from "utils/objects";
import handleErrors from "utils/handleErrors";

const { mediaQuery } = responsive;

const initialState = {
  country: "Spain",
  taxId: "",
  businessName: "",
  city: "",
  streetName: "",
  province: "",
  buildingNumber: "",
  postalCode: "",
};

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
  INVALID_TAX_PROFILE_DATA: errors.invalidTaxProfileData,
  MANDATORY_FIELD_MISSING: errors.mandatoryFieldMissing,
};

const BillingDataUpsert = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const isNew = !params.id || params.id === "new";

  const [state, setState] = useState(initialState);

  const [currMode, setCurrMode] = useState(modes.INITIAL);

  const isBackendErrorMode =
    currMode !== modes.INITIAL && currMode !== modes.MANDATORY_FIELD_MISSING;

  const isFieldErrorMode = currMode === modes.MANDATORY_FIELD_MISSING;

  useEffect(() => {
    if (params.id) {
      getTaxProfileData(params.id)
        .then(({ data }) => {
          if (data) {
            const { taxAddress, ...otherData } = data;
            setState({ ...taxAddress, ...otherData });
          }
        })
        .catch(() => history.push("/billing-data/new"));
    }
  }, [params.id]);

  const updateState = (nextState, updateStateFn) =>
    updateStateFn((currentState) => ({ ...currentState, ...nextState }));

  const onInputChange = ({ target: { name, value } }) => {
    updateState({ [name]: value }, setState);
    if (currMode !== modes.INITIAL && !isEmptyObject(state))
      setCurrMode(modes.INITIAL);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isEmptyObject(state)) {
      setCurrMode(modes.MANDATORY_FIELD_MISSING);
    } else {
      const { taxId, businessName, country, ...taxAddress } = state;
      createTaxProfileRequest({
        taxId,
        businessName,
        country: "ES",
        taxAddress,
      })
        .then(() => {
          setState(initialState);
          history.push("/billing-data");
        })
        .catch((catchElement) => {
          handleErrors({
            ...catchElement,
            modes,
            setMode: (mode) => updateState({ mode }, setCurrMode),
          });
        });
    }
  };

  return (
    <>
      <Container>
        <Form onSubmit={onSubmit}>
          {Object.keys(initialState).map((property, index) => {
            const type = property === "buildingNumber" ? "number" : "text";
            return (
              <div key={index} style={{ display: "inline-block" }}>
                {isFieldErrorMode && state[property] === "" && (
                  <Text
                    color={currMode.message.color}
                    data-testid={"missing-field-on-tax-profile-error-message"}
                  >
                    {t(currMode.message.translation)}
                  </Text>
                )}
                <CusotmInput
                  readOnly={!isNew || property === "country"}
                  label={t(`billingData.${property}`)}
                  name={property}
                  id={property}
                  onChange={onInputChange}
                  value={property === "country" ? "España" : state[property]}
                  type={type}
                />
              </div>
            );
          })}
          {isNew && (
            <Button
              color="primary"
              style={{ fontWeight: "bold", textTransform: "none" }}
              type="submit"
            >
              {t("actions.save")}
            </Button>
          )}
          {isBackendErrorMode && (
            <Text color={currMode.message.color}>
              {t(currMode.message.translation)}
            </Text>
          )}
        </Form>
      </Container>
      <Navigation
        mode="vertical"
        leftButton={{
          show: false,
        }}
        rightButton={{
          children: t("actions.back"),
          color: "warning",
          onClick: () => {
            history.push("/billing-data");
          },
        }}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Form = styled.form`
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.TABLET`    
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      flex-basis: 48%;
    }
  `}
`;

const CusotmInput = styled(Input)`
  margin-bottom: 20px;
`;

export default BillingDataUpsert;
