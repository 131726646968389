import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link, Text, responsive } from "bluejay-ui";
import { Input } from "ui/";
import { isEmptyObject } from "utils/objects";
import { errors } from "utils/modesErrors";
import CustomButton from "ui/Button";

const { mediaQuery } = responsive;

const Form = styled.form`
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.TABLET`    
    width: 350px;
  `}
`;

const LabelConditions = styled.label`
  display: inline;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 0.8rem;
`;

const modes = {
  INITIAL: { name: "INITIAL", url: "clients/v1/users" },
  MANDATORY_FIELD_MISSING: errors.mandatoryFieldMissing,
};

const UnAuthForm = ({
  onSubmit,
  title,
  conditions,
  submitText,
  secondaryText,
  secondaryLink,
  secondaryTo,
  fields = [
    {
      name: "email",
    },
    {
      name: "password",
    },
  ],
  message = {},
  children,
  resetMode = () => {},
  hideTitleAndLink = false,
}) => {
  const { t } = useTranslation();

  const [mode, setMode] = useState(modes.INITIAL);

  const [credentials, setCredentials] = useState(initialStateFields(fields));

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onInputChange = (e, field) => {
    setIsButtonDisabled(false);
    setCredentials({ ...credentials, [field.name]: e.target.value });
    resetMode();
    if (mode !== modes.INITIAL && !isEmptyObject(credentials))
      setMode(modes.INITIAL);
  };

  const onLocalSubmit = (e) => {
    resetMode();
    e.preventDefault();
    setIsButtonDisabled(true);
    onSubmit(credentials);
  };

  const handleCredentialsErrors = () => {
    setMode(modes.MANDATORY_FIELD_MISSING);
  };

  function AcceptConditions({ htmlContent }) {
    return (
      <LabelConditions dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  }
  const htmlStringConditions = conditions;

  useEffect(() => {
    if (Object.entries(message).length > 0 && isButtonDisabled) {
      setIsButtonDisabled(false);
    }
  }, [message]);

  return (
    <Form onSubmit={onLocalSubmit}>
      {!hideTitleAndLink && (
        <>
          <span
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {title}
          </span>

          <span
            style={{
              margin: "35px auto 50px auto",
              textAlign: "center",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {secondaryText}
            <Link as={RouterLink} to={secondaryTo} color="primary">
              {secondaryLink}
            </Link>
          </span>
        </>
      )}

      {fields.map((field, index) => (
        <div key={index}>
          {mode !== modes.INITIAL && credentials[field.name] === "" && (
            <Text
              color={mode.message.color}
              data-testid={"missing-field-on-login-error-message"}
            >
              {t(mode.message.translation)}
            </Text>
          )}
          <Input
            missing-field-on-login-error-message
            {...field}
            onChange={(e) => onInputChange(e, field)}
            value={credentials[field.name]}
          ></Input>
        </div>
      ))}

      {message && (
        <Text
          color={message.color}
          style={{ margin: "auto auto 30px auto", textAlign: "center" }}
          data-testid={"unauth-error-message"}
        >
          {t(message.translation)}
        </Text>
      )}

      {submitText && (
        <>
          <AcceptConditions htmlContent={htmlStringConditions} />
          <CustomButton
            color="primary"
            className="progress"
            style={{ fontWeight: "bold", textTransform: "none" }}
            type="submit"
            data-testid="unauth-submit"
            disabled={isButtonDisabled}
            onClick={(e) => {
              if (isEmptyObject(credentials)) {
                e.preventDefault();
                handleCredentialsErrors();
                setIsButtonDisabled(false);
              } else {
                return null;
              }
            }}
          >
            {submitText}
          </CustomButton>
        </>
      )}

      {children}
    </Form>
  );
};

function initialStateFields(fields = []) {
  return fields.reduce((reducer, field) => {
    const fieldName = field.name;
    return { ...reducer, [fieldName]: "" };
  }, {});
}

export default UnAuthForm;
