import request from "./request";
import handleErrors from "./handleErrors";

export const loginRequest = (credentials) =>
  request("clients/v1/users/login", {
    method: "POST",
    body: JSON.stringify(credentials),
  }).then(({ data: { access_token, refresh_token } }) => ({
    token: access_token,
    refreshToken: refresh_token,
  }));

export const doRequestAndLocalLogin = ({
  credentials,
  localLogin,
  handleErrorsParams = {},
}) =>
  loginRequest(credentials)
    .then(({ token, refreshToken }) =>
      getUser({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(({ data: { user } }) => localLogin({ token, refreshToken, user }))
    )
    .catch((catchElement) =>
      handleErrors({ ...catchElement, ...handleErrorsParams })
    );

export const getUser = (options) =>
  request(
    `mobile/v1/client?include[1][name]=resource&include[1][filters][0][key]=resource_type&include[1][filters][0][operator]=eq&include[1][filters][0][value]=originAddress&include[1][attributes][0]=relationData`,
    options
  );

export const registerRequest = ({ url, credentials }) =>
  request(url, {
    method: "POST",
    body: JSON.stringify({
      ...credentials,
      countryCode: "+34",
    }),
  });

export const bookTripRequest = (props) =>
  request(`mobile/v1/trips`, {
    method: "POST",
    body: JSON.stringify(props),
  });

export const getPlaceSuggestionsRequest = (search) =>
  fetch(
    `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?${toQueryString(
      { key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY, input: search }
    )}`
  );

export const resendSMSCode = (credentials) =>
  request(`clients/v1/users/${credentials.email}/activate/sms`);

export const companiesRequest = ({ lat, lng }) =>
  request(`mobile/v1/company/coords?paymentMethod=cash&lat=${lat}&long=${lng}`);

export const companyPOIsRequest = ({ companyId }) =>
  request(`mobile/v1/companies/${companyId}/pois`);

export const myTripsRequest = (url) => request(url);

export const tripRequest = (id) => request(`trips/v1/trips/${id}`);

export const cancelTripRequest = (id) =>
  request(`trips/v1/trips/${id}`, {
    method: "DELETE",
  });

export const getTaxProfileData = (id) =>
  request(`mobile/v1/client/taxProfiles/${id}?country=ES`);

export const deleteTaxProfileRequest = (id) =>
  request(`mobile/v1/client/taxProfiles/${id}?country=ES`, {
    method: "DELETE",
  });

export const taxProfilesRequest = (options) =>
  request(`mobile/v1/client/taxProfiles`, options);

export const createTaxProfileRequest = (data) =>
  request(`mobile/v1/client/taxProfiles`, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const generateTripInvoiceRequest = ({ id, taxId }) =>
  request(`mobile/v1/trips/${id}/invoices`, {
    method: "POST",
    body: JSON.stringify({ taxId, country: "ES" }),
  });

export const addAddressToFavourites = ({ name, isDefault, address }) =>
  request(`trips/v1/favouritePlaces`, {
    method: "POST",
    body: JSON.stringify({ name, address, isDefault }),
  });

export const removeAddressFromFavourites = (id) =>
  request(`trips/v1/favouritePlaces/${id}`, {
    method: "DELETE",
  });

export const getFavouriteAddresses = () => request("trips/v1/favouritePlaces");

export const getAgreedPriceTripEstimation = (data) =>
  request("trips/v1/trips/fixed", {
    method: "POST",
    body: JSON.stringify(data),
  });

export const getTaximeterTripEstimation = (data) =>
  request("trips/v1/trips/estimate", {
    method: "POST",
    body: JSON.stringify(data),
  });

export const changeUserPassword = (data) =>
  request("clients/v1/users/change-password", {
    method: "PUT",
    body: JSON.stringify(data),
  });

const toQueryString = (params) =>
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
