import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Label, Navigation, TripSummary } from "ui";
import { getWellNamedAddresses } from "utils/trip";
import { appTypes, getAppType } from "utils/app";
import { Text } from "bluejay-ui";
import handleErrors from "utils/handleErrors";
import { errors } from "utils/modesErrors";
import { priceTypes } from "takasi-library/src/enums";
const appType = getAppType();

const modes = {
  INITIAL: {
    name: "INITIAL",
  },
  NO_CAR_COVERAGE: errors.noCarCoverage,
  INVALID_BOOKING_DATE: errors.invalidBookingDate,
  CLIENT_BLOCKED: errors.clientBlocked,
  INVALID_SUBSCRIBER_DATA: errors.invalidSubscriberData,
  DESTINATION_IS_MANDATORY: errors.destinationIsMandatory,
  SCHEDULED_TRIP_NOT_ALLOWED: errors.scheduledTripNotAllowed,
  SUBSCRIBER_ACCOUNT_NOT_FOUND: errors.subscriberNotFound,
  SUBSCRIBER_ACCOUNT_NOT_ACTIVE: errors.subscriberAccountNotActive,
  NUMBER_OF_BAGS_IS_MANDATORY: errors.numberOfBagsIsMandatory,
  NUMBER_OF_PASSENGERS_IS_MANDATORY: errors.numberOfPassengersIsMandatory,
  TIME_ZONE_CONVERSION_ERROR: errors.timezoneConversionError,
  SIMULTANEOUS_TRIP_ERROR: errors.simultaneousTripError,
  VEHICLE_NOT_AVAILABLE: errors.vehicleNotAvailable,
  INVALID_PICKUP_TOO_CLOSE_TO_STAND: errors.invalidPickupTooCloseToStand,
  NO_CAR_MEET_REQUIREMENTS: errors.noCarMeetRequirements,
};

const BookSummary = ({
  trip,
  setRoutesEnabled,
  updateBooking,
  onBookNowClick,
  updateRoutes,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [state, setState] = useState({
    mode: modes.INITIAL,
  });

  const isErrorMode = state.mode !== modes.INITIAL;

  useEffect(() => {
    updateBooking({ disabled: true });
    setRoutesEnabled(false);
  }, []);

  const updateState = (nextState) =>
    setState((currentState) => ({ ...currentState, ...nextState }));

  const { pickupName, destinationName } = getWellNamedAddresses(trip);

  const onLocalBookNowClick = () => {
    if (
      (trip.agreedPriceType === priceTypes.fixedPrice ||
        trip.agreedPriceType === priceTypes.maxPrice) &&
      !destinationName
    ) {
      updateState({ mode: modes.DESTINATION_IS_MANDATORY });

      return;
    }
    onBookNowClick()
      .then((data) => {
        history.push({
          pathname: `/trips/${data[0].data}`,
          state: { trips: data.map(({ data }) => data) },
        });
      })
      .catch((e) =>
        handleErrors({
          ...e,
          modes,
          setState: (mode) => updateState({ mode }),
        })
      );
  };

  const goBack = () => {
    history.goBack();
    setRoutesEnabled(true);
  };

  const goBackAndResetRoutes = () => {
    history.goBack();
    updateRoutes({
      active: "origin",
      destination: { name: "" },
      disabled: false,
      ...(appType !== appTypes.HOTEL ? { origin: { name: "" } } : {}),
    });
  };

  const wellNamedTrip = {
    ...trip,
    pickupAddress: {
      ...trip.pickupAddress,
      name: pickupName,
    },
    ...(trip.destinationAddress
      ? {
          destinationAddress: {
            ...trip.destinationAddress,
            name: destinationName,
          },
        }
      : {}),
  };

  return (
    <>
      <Label>{t("book.summary")}</Label>
      <TripSummary trip={wellNamedTrip} />
      {isErrorMode && (
        <Text
          color={state.mode.message.color}
          style={{ margin: "auto auto 30px auto", textAlign: "center" }}
        >
          {t(state.mode.message.translation)}
        </Text>
      )}
      <Navigation
        leftButton={{
          children: t("actions.back"),
          onClick: isErrorMode ? goBackAndResetRoutes : goBack,
        }}
        rightButton={{
          disabled: !trip.isValid || isErrorMode,
          children: t("book.confirm"),
          onClick: onLocalBookNowClick,
        }}
      />
    </>
  );
};

export default BookSummary;
