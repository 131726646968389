export const status = {
  INPROGRESS: { name: "inprogress", url: "trips/v1/trips/in-progress" },
  SCHEDULED: { name: "scheduled", url: "trips/v1/trips/scheduled" },
  HISTORY: { name: "history", url: "trips/v1/trips/history" },
};

export const mapTakasiTrip = (trip) => ({
  ...trip,
  pickupAddress: {
    ...trip.pickupAddress,
    name: `${trip.pickupAddress.streetName}, ${trip.pickupAddress.buildingNumber}, ${trip.pickupAddress.cityName}`,
  },
  ...(trip.destinationAddress && !objectIsEmpty(trip.destinationAddress)
    ? {
        destinationAddress: {
          ...trip.destinationAddress,
          name: `${trip.destinationAddress.streetName}, ${trip.destinationAddress.buildingNumber}, ${trip.destinationAddress.cityName}`,
        },
      }
    : {}),
});

export const getWellNamedAddresses = (trip) => {
  const pickupName = `${trip.pickupAddress?.streetName},${
    trip.pickupAddress?.buildingNumber &&
    trip.pickupAddress.buildingNumber !== "0"
      ? ` ${trip.pickupAddress.buildingNumber},`
      : ""
  } ${trip.pickupAddress.cityName}, ${trip.pickupAddress.countryName}`;

  const destinationName = `${trip.destinationAddress?.streetName}, ${
    trip.destinationAddress?.buildingNumber &&
    trip.destinationAddress.buildingNumber !== "0"
      ? `${trip.destinationAddress.buildingNumber},`
      : ""
  } ${trip.destinationAddress?.cityName}, ${
    trip.destinationAddress?.countryName
  }`;

  return {
    pickupName,
    destinationName: trip.destinationAddress?.streetName
      ? destinationName
      : null,
  };
};

const objectIsEmpty = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
