const handleErrors = ({ error, modes, setMode = () => {}, status }) => {
  const nextMode = error
    ? Object.values(modes).find((v) => v.errorCode === error.code) ||
      modes.SERVER_OFFLINE
    : modes.SERVER_OFFLINE;

  if (nextMode) {
    setMode(nextMode);
  }
  return nextMode;
};

export default handleErrors;
