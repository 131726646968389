import React from "react";
import styled from "styled-components";
import { Input, Label } from "bluejay-ui";

const StyledInput = styled(Input)`
  background-color: rgba(160, 160, 160, 0.12);
  margin-bottom: 35px;
  border-radius: 0px;
  border: none;
  border-bottom: 2px solid #a0a0a0;

  ::placeholder {
    color: #c4c4c4;
    text-transform: lowercase;
  }
`;

const CustomLabel = styled(Label)`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
`;

const CustomInput = (props) => <StyledInput {...props} Label={CustomLabel} />;

export default CustomInput;
