export const initialLocation = {
  lat: 40.41697,
  lng: -3.7033805312133836,
};

export const mapStates = {
  NORMAL: "NORMAL",
  BIG: "BIG",
  INVISIBLE: "INVISIBLE",
};

export const searchModes = {
  SUGGEST: "SUGGEST",
  FAVOURITES: "FAVOURITES",
};

const bigMapRoutes = [/trips\//, /profile/, /change-password/];
export const isBigMapRoute = (route) =>
  bigMapRoutes.some((regex) => regex.test(route));

const invisibleMapRoutes = [/trips/, /profile/, /change-password/];
export const isInvisibleMapRoute = (route) =>
  invisibleMapRoutes.some((regex) => regex.test(route));

export const GoogleServices = ({ map, maps }) => {
  return {
    autocomplete: new maps.places.AutocompleteService(),
    places: new maps.places.PlacesService(map),
    geocoder: new maps.Geocoder(),
    getMapCenterFromLocations: (locations) => {
      const bounds = new window.google.maps.LatLngBounds();

      locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.latitude, location.longitude)
        );
      });

      map.fitBounds(bounds);

      return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
    },
  };
};

export const geocodePlace = (center, googleServices) => {
  center = Object.keys(center).length === 0 ? { lat: 0, lng: 0 }: center;
  return new Promise((resolve, reject) => {
    if (googleServices.geocoder) {
      googleServices.geocoder.geocode(
        { location: { lat: center.lat !== undefined ? center.lat: center.latitude, lng: center.lng !== undefined ? center.lng: center.longitude } },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              resolve(results[0]);
            }
          }
        }
      );
    }
  });
};

const preferences = [
  "route",
  "point_of_interest",
  "airport",
  "bus_station",
  "train_station",
  "transit_station",
  "street_address",
  "premise",
  "neighborhood",
  "establishment",
];

export const getBestRoutesFirst = (data) =>
  preferences.reduce((reducer, preference) => {
    const match = data?.filter(
      ({ types, place_id }) =>
        types.includes(preference) &&
        !reducer.find((reducerElement) => reducerElement.place_id === place_id)
    );
    return [...reducer, ...(match && match.length ? match : [])];
  }, []);
